import { ethers } from 'ethers'
import { COLLECTIONS } from '../config'
import NFTX from '../assets/marketplaces/nftx.png'
import SUDOSWAP from '../assets/marketplaces/sudoswap.png'

export default function NFTCard_V2(props) {
  const {
    nft,
    nftContract,
    marketplace,
    poolLiquidity,
    txModal,
    isMobile,
  } = props

  const maxLoan = COLLECTIONS[nftContract].maxLoan

  const handleBuy = () => {
    if (!txModal.isTxModalOpen) {
      console.log('marketplace', marketplace)
      console.log('nft', nft)
      txModal.sendTx(nft)
      txModal.setIsTxModalOpen(true, nft)
    }
  }

  const getImage = (_url) => {
    let url = _url
    if (!url) return ''

    if (url?.indexOf('/ipfs/') !== -1) {
      url = 'https://bailout.mypinata.cloud/ipfs/' + url?.split('ipfs/')[1]
    }
    return url
  }

  return (
    <div
      key={nft.tokenSetId}
      className={` relative flex flex-col w-full shrink-0 grow snap-normal snap-center bg-white shadow-md overflow-hidden overscroll-contain rounded-t-md`}
    >
      <div
        onClick={() => {
          window.open(nft.sourceUrl, '_blank', 'noopener,noreferrer')
        }}
        className="z-50 absolute top-0 right-0 bg-white rounded-full p-1 m-2 shadow rounded-t-md"
      >
        <img
          className={`h-5 w-5`}
          src={
            nft.kind === 'nftx'
              ? NFTX
              : nft.kind === 'sudoswap'
              ? SUDOSWAP
              : nft.sourceIcon
          }
        />
      </div>

      <img
        className={`aspect-square w-full`}
        style={{ aspectRatio: '1/1' }}
        src={getImage(nft.nftImage)}
      />

      <div className="bg-white z-50  pt-0 pb-2 text-xl ">
        <div className="flex flex-col  w-full">
          <div className="bg-green-50 inline-flex text-sm text-green-800/80 border-b border-green-300 justify-center px-2">
            Full Price
            <div className="inline-flex pl-1 flex-row items-center text-green-800/80 font-semibold">
              {Number(
                Math.floor(
                  Number(ethers.utils.formatEther(nft.price).toString()) * 1000,
                ) / 1000,
              ).toFixed(3)}
              <div className="inline-flex pl-1">
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 6 9"
                  fill="inherit"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
                    fill="#777E91"
                  />
                  <path
                    d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
                    fill="#777E91"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full justify-center ">
            <div className=" inline-flex text-xs whitespace-nowrap  pt-2 justify-center px-1">
              Downpayment
              <div className="inline-flex pl-[2px] flex-row items-center text-green-800 font-semibold">
                {(
                  Number(ethers.utils.formatEther(nft.price).toString()) -
                  maxLoan
                ).toFixed(2)}
                <div className="inline-flex ">
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 6 9"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
                      fill="#777E91"
                    />
                    <path
                      d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
                      fill="#777E91"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              onClick={handleBuy}
              className=" bg-green-500 mx-2 mt-1 rounded-full py-1 items-center whitespace-nowrap text-white text-sm tracking-tight px-4 flex flex-row justify-center cursor-pointer hover:shadow"
            >
              Buy now pay later
            </div>
            {/*
            <CheckoutButton
              marketplace={marketplace}
              nft={{ ...nft, address: nftContract }}
              txModal={txModal}
              isDisabled={
                Math.floor(
                  Number(
                    ethers.BigNumber.from(
                      ethers.utils.parseUnits('25', 'ether'),
                    )
                      .div(
                        ethers.utils.parseEther(
                          COLLECTIONS[nftContract].maxLoan.toString(),
                        ),
                      )
                      .toString(),
                  ),
                ) === 0
              }
            />
            */}
          </div>
        </div>
      </div>
    </div>
  )
}
