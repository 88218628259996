import ethers from 'ethers'
import { ConnectButton } from '@rainbow-me/rainbowkit'

const BranchTable = (props) => {
  const { branches } = props

  return (
    <div className="flex flex-col">
      <div className="max-w-full overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden ">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-2 pr-2 sm:pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Max Loan
                  </th>
                  <th
                    scope="col"
                    className="px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Deposits
                  </th>
                  <th
                    scope="col"
                    className="px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Borrowed
                  </th>
                  <th
                    scope="col"
                    className="hidden sm:table-cell px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Loans
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {branches.map(({ branch, metadata }) => (
                  <tr key={branch.address}>
                    <td className="whitespace-nowrap py-4 pl-2 pr-2 sm:pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={metadata.image}
                            alt=""
                          />
                        </div>
                        <div className="pl-2 sm:pt-0 sm:ml-4 max-w-[230px]">
                          <div className="font-medium text-gray-900">
                            {metadata.name}
                          </div>
                          <div className="text-ellipsis overflow-hidden text-gray-500">
                            {Number(metadata.tokenCount).toLocaleString(
                              'en-US',
                            ) + ' NFTs'}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="hidden sm:table-cell whitespace-nowrap px-2 sm:px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900 font-mono">
                        {Number(branch.maxLoanSize).toFixed(2) + ' ETH'}
                      </div>
                      <div className="text-gray-500">{'7 Days'}</div>
                    </td>
                    <td className="whitespace-nowrap px-2 sm:px-3 py-4 font-mono text-sm text-green-500 ">
                      {Number(branch.totalSupply).toFixed(2) + ' ETH'}
                    </td>
                    <td className="whitespace-nowrap px-2 sm:px-3 py-4 font-mono text-sm text-yellow-500">
                      {Number(branch.totalDebt).toFixed(2) + ' ETH'}
                    </td>
                    <td className="hidden sm:table-cell whitespace-nowrap px-2 sm:px-3 py-4 text-sm text-gray-500">
                      {branch.loans.length}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BranchTable
