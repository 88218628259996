import { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { CheckIcon } from '@heroicons/react/20/solid'
import { CONTRACTS, ABI } from '../config.js'
import {
  getBranches,
  getUserLoans,
  getUserWethBalanceAndApprovals,
} from '../helpers/bailout.js'
import CollateralCard from '../components/CollateralCard'

const ETH_USD_PRICE = 1535 // To-do: fetch from API

export default function Wallet(props) {
  let navigate = useNavigate()

  let params = useParams()
  const signer = useSigner()
  const { address: wallet, isConnected: isWalletConnected } = useAccount()
  let targetWallet = params.address ? params.address : wallet
  const { data: walletBalance, isError, isFetched } = useBalance({
    addressOrName: targetWallet,
  })

  const [isLoading, setIsLoading] = useState(true)

  const [wethBalance, setWethBalance] = useState('0')
  const [wethApprovals, setWethApprovals] = useState('0')

  const [isLoadingLoans, setIsLoadingLoans] = useState(true)
  const [allBranches, setAllBranches] = useState([])
  const [loans, setLoans] = useState([])

  const [isTxModalOpen, setIsTxModalOpen] = useState(false)
  const [txData, setTxData] = useState({})

  useEffect(() => {
    const allBranches_cacheKey = `allBranches_wallet`
    let allBranchesCache = localStorage.getItem(allBranches_cacheKey)
    if (allBranchesCache) {
      allBranchesCache = JSON.parse(allBranchesCache)
      setAllBranches(allBranches)
    }

    const userLoans_cacheKey = `userLoans:${targetWallet}`
    let userLoansCaches = localStorage.getItem(userLoans_cacheKey)
    if (userLoansCaches) {
      userLoansCaches = JSON.parse(userLoansCaches)
      setLoans(userLoansCaches)
      setIsLoadingLoans(false)
    }

    getBranches().then((activeBranches) => {
      setAllBranches(activeBranches)
    })
  }, [])

  useEffect(() => {
    if (allBranches.length > 0 && isWalletConnected) {
      getUserWethBalanceAndApprovals(targetWallet, allBranches).then(
        (result) => {
          const { _wethBalance, _wethApprovals } = result
          let formattedWethApprovals = {}
          allBranches.forEach((branch, index) => {
            formattedWethApprovals[branch.address] = _wethApprovals[
              index
            ].toString()
          })

          setIsLoading(false)
          setWethBalance(_wethBalance.toString())
          setWethApprovals(formattedWethApprovals)
        },
      )

      const userLoans_cacheKey = `userLoans:${targetWallet}`
      getUserLoans(targetWallet, allBranches).then((result) => {
        setLoans(result)
        setIsLoadingLoans(false)
        localStorage.setItem(userLoans_cacheKey, JSON.stringify(result))
      })
    }
  }, [allBranches, isWalletConnected])

  console.log('wethBalance', wethBalance)

  const [isApproving, setIsApproving] = useState(false)
  const [isWrapping, setIsWrapping] = useState(false)
  const [isRepaying, setIsRepaying] = useState(false)
  const [isRepaid, setIsRepaid] = useState(false)

  const approveBranch = (txData) => {
    if (!isApproving) {
      setIsApproving(true)
      const branchAddress = txData.branchAddress
      const WETH_contract = new ethers.Contract(
        CONTRACTS.WETH,
        ABI.WETH,
        signer.data,
      )
      WETH_contract.approve(branchAddress, ethers.constants.MaxUint256)
        .then((txResponse) => {
          txResponse.wait().then(() => {
            getUserWethBalanceAndApprovals(targetWallet, allBranches).then(
              (result) => {
                const { _wethBalance, _wethApprovals } = result
                setWethBalance(_wethBalance.toString())
                let formattedWethApprovals = {}
                allBranches.forEach((branch, index) => {
                  formattedWethApprovals[branch.address] = _wethApprovals[
                    index
                  ].toString()
                })
                setWethApprovals(formattedWethApprovals)
                setIsApproving(false)
              },
            )
          })
        })
        .catch((error) => {
          //console.log(error)
          setIsApproving(false)
        })
    }
  }

  const wrapEth = (txData) => {
    if (!isWrapping) {
      setIsWrapping(true)
      const WETH_contract = new ethers.Contract(
        CONTRACTS.WETH,
        ABI.WETH,
        signer.data,
      )
      let totalDebt = ethers.utils.parseEther(txData.debt.current)
      let amountNeeded = totalDebt.sub(wethBalance).add(totalDebt.div(100)) // add 1% to account for debt increasing each block.
      WETH_contract.deposit({ value: amountNeeded })
        .then((txResponse) => {
          txResponse.wait().then(() => {
            getUserWethBalanceAndApprovals(targetWallet, allBranches)
              .then((result) => {
                const { _wethBalance, _wethApprovals } = result
                setWethBalance(_wethBalance.toString())
                let formattedWethApprovals = {}
                allBranches.forEach((branch, index) => {
                  formattedWethApprovals[branch.address] = _wethApprovals[
                    index
                  ].toString()
                })
                setWethApprovals(formattedWethApprovals)
                setIsWrapping(false)
              })
              .catch((error) => {
                setIsWrapping(false)
              })
          })
        })
        .catch((error) => {
          console.log(error)
          setIsWrapping(false)
        })
    }
  }

  const repayLoan = (txData) => {
    if (!isRepaying && !isRepaid) {
      setIsRepaying(true)
      const branchContract = new ethers.Contract(
        txData.branchAddress,
        ABI.BRANCH,
        signer.data,
      )
      branchContract
        .closeSubprimeMortgage(txData.loanId, { gasLimit: 300000 })
        .then((txResponse) => {
          txResponse.wait().then(() => {
            getUserWethBalanceAndApprovals(targetWallet, allBranches)
              .then((result) => {
                const { _wethBalance, _wethApprovals } = result
                setWethBalance(_wethBalance.toString())
                let formattedWethApprovals = {}
                allBranches.forEach((branch, index) => {
                  formattedWethApprovals[branch.address] = _wethApprovals[
                    index
                  ].toString()
                })
                setWethApprovals(formattedWethApprovals)
                setIsRepaid(true)
                setIsRepaying(false)

                setIsLoadingLoans(true)
                getUserLoans(allBranches).then((result) => {
                  setLoans(result)
                  setIsLoadingLoans(false)
                })
              })
              .catch((error) => {
                //console.log(error)
                setIsWrapping(false)
              })
          })
        })
        .catch((error) => {
          console.log(error)
          setIsRepaying(false)
        })
    }
  }

  const RepaymentSteps = () => {
    console.log('txData', txData)
    console.log('wethBalance', wethBalance)
    //  console.log('wethApprovals', wethApprovals)
    //  console.log('walletBalance', walletBalance)
    //  console.log('allBranches', allBranches)

    let totalDebt = ethers.utils.parseEther(txData.debt.current)
    let totalUserBalance = walletBalance.value.add(wethBalance)

    let activeStep = 0
    let insufficientBalance = false
    if (totalDebt.gt(wethApprovals[txData.branchAddress])) {
      activeStep = 0
    } else {
      if (ethers.BigNumber.from(wethBalance).lt(totalDebt)) {
        activeStep = 1
        if (totalUserBalance.lt(totalDebt)) {
          insufficientBalance = true
        }
      } else {
        activeStep = 2

        if (isRepaid) {
          activeStep = 3
        }
      }
    }

    //  console.log('activeStep', activeStep)
    return (
      <div aria-label="Progress" className="pt-1">
        <ol role="list" className="overflow-hidden">
          <li key={'approval'} className="pb-6 relative">
            <>
              <div
                className={`${
                  activeStep > 0 ? 'bg-green-500' : 'bg-gray-300'
                } absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 `}
                aria-hidden="true"
              />
              <div className="group relative flex items-start">
                <div className="flex h-9 items-center">
                  <span
                    className={`${
                      activeStep > 0 ? 'bg-green-500' : 'border-2  bg-white'
                    } relative z-10 flex h-8 w-8 items-center justify-center rounded-full `}
                  >
                    {activeStep > 0 && (
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    )}
                    {activeStep === 0 && (
                      <span className="h-2.5 w-2.5 rounded-full bg-green-500 " />
                    )}
                  </span>
                </div>
                <div className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium">Approve WETH</span>
                  <span className="text-sm text-gray-500">
                    This lets the branch pull the second installment.
                  </span>
                </div>
                <div className="pt-2 h-full flex items-center min-w-[91px]">
                  <div
                    onClick={() => {
                      if (activeStep === 0) {
                        approveBranch(txData)
                      }
                    }}
                    className={`${
                      activeStep > 0
                        ? 'text-gray-400 border border-gray-200'
                        : 'bg-gradient-to-tr from-green-400 via-green-500 to-green-400 font-bold text-white shadow cursor-pointer hover:opacity-90'
                    } w-full  rounded-md  px-2 py-2 flex justify-center items-center `}
                  >
                    {isApproving ? (
                      <svg
                        className="animate-spin h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : activeStep > 0 ? (
                      'Approved'
                    ) : (
                      'Approve'
                    )}
                  </div>
                </div>
              </div>
            </>
          </li>
          <li key={'wrapEth'} className="pb-6 relative">
            <>
              <div
                className={`${
                  activeStep > 1 ? 'bg-green-500' : 'bg-gray-300'
                } absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 `}
                aria-hidden="true"
              />
              <div
                className="group relative flex items-start"
                aria-current="step"
              >
                <span className=" flex h-9 items-center" aria-hidden="true">
                  <span
                    className={`${
                      activeStep > 1 ? 'bg-green-500' : 'border-2  bg-white'
                    } relative z-10 flex h-8 w-8 items-center justify-center rounded-full `}
                  >
                    {activeStep > 1 && (
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    )}
                    {activeStep === 1 && (
                      <span className="h-2.5 w-2.5 rounded-full bg-green-500 " />
                    )}
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span
                    className={`${
                      activeStep === 1 ? '' : 'text-gray-500'
                    } text-sm font-medium `}
                  >
                    Wrap ETH
                  </span>
                  <span className="text-sm text-gray-500">
                    You can only repay with WETH, click to wrap.
                  </span>
                </span>
                <div className="pt-2 h-full flex items-center min-w-[91px]">
                  <div
                    onClick={() => {
                      if (activeStep === 1) {
                        wrapEth(txData)
                      }
                    }}
                    className={`${
                      activeStep === 1
                        ? 'bg-gradient-to-tr from-green-400 via-green-500 to-green-400 font-bold text-white shadow cursor-pointer hover:opacity-90'
                        : activeStep > 1
                        ? 'text-gray-400 border border-gray-200'
                        : 'bg-gray-100 text-gray-400'
                    } w-full  rounded-md  px-2 py-2 flex justify-center items-center `}
                  >
                    {isWrapping ? (
                      <svg
                        className="animate-spin h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      'Wrap'
                    )}
                  </div>
                </div>
              </div>
            </>
          </li>
          <li key={'repay'} className="pb-6 relative">
            <>
              <div className="group relative flex items-start">
                <span className="flex h-9 items-center" aria-hidden="true">
                  <span
                    className={`${
                      isRepaid ? 'bg-green-500' : 'border-2  bg-white'
                    } relative z-10 flex h-8 w-8 items-center justify-center rounded-full `}
                  >
                    {isRepaid && (
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    )}
                    {activeStep === 2 && (
                      <span className="h-2.5 w-2.5 rounded-full bg-green-500 " />
                    )}
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium text-gray-500">
                    Pay Installment
                  </span>
                  <span className="text-sm text-gray-500">
                    You will receive the nft right after this payment.
                  </span>
                </span>
                <div className="pt-2 h-full flex items-center min-w-[91px] max-w-[91px]">
                  <div
                    onClick={() => {
                      if (activeStep === 2 && !isRepaid && !isRepaying) {
                        repayLoan(txData)
                      }
                    }}
                    className={`${
                      activeStep === 2
                        ? 'bg-gradient-to-tr from-green-400 via-green-500 to-green-400 font-bold text-white shadow cursor-pointer hover:opacity-90'
                        : activeStep > 2
                        ? 'text-gray-400 border border-gray-200'
                        : 'bg-gray-100 text-gray-400'
                    } w-full  rounded-md  px-2 py-2 flex justify-center items-center `}
                  >
                    {isRepaying ? (
                      <svg
                        className="animate-spin h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : isRepaid ? (
                      'Repaid'
                    ) : (
                      'Pay'
                    )}
                  </div>
                </div>
              </div>
            </>
          </li>
        </ol>
      </div>
    )
  }

  const getNftImage = (loan) => {
    if (loan.nftMedia) {
      let url = loan.nftMedia[0].thumbnail
        ? loan.nftMedia[0].thumbnail
        : loan.nftMedia[0].gateway

      if (url === 'https://miladymaker.net/milady/5486.png') {
        url =
          'https://lh3.googleusercontent.com/TEuItW8grssxcpbgDDyNxr8JpcaU-eZJyJS0rnoexzRuG1_TayNGIQ52R0DS1StrUQkCakZUmW9SWVrqD2UE_82BJ7JL5NtZ_e0tnA=s0'
      }
      return url
    }
  }

  return (
    <div className="absolute w-full h-full flex flex-col items-center bg-gray-50">
      <Helmet>
        <title>Bailout - Wallet: {targetWallet}</title>
      </Helmet>
      <Transition.Root show={isTxModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            if (!isApproving && !isWrapping && !isRepaying) {
              setIsTxModalOpen(false)
            } else {
              setIsTxModalOpen(true)
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto flex flex-col justify-center">
            <div className="flex items-center md:items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg shadow-xl transition-all sm:w-full sm:max-w-sm bg-gradient-to-tr from-green-500 via-green-600 to-green-500">
                  <div className="w-full h-8 rounded-t-lg">
                    <div className={`h-full w-[5%]  rounded-t-lg`} />
                  </div>
                  <div className=" bg-white px-4 pt-4 pb-4 text-left sm:px-6 ">
                    <CollateralCard txData={txData} />
                    <RepaymentSteps />
                  </div>
                  <div className="w-full h-8 rounded-b-lg">
                    <div className={` h-full w-[5%]  rounded-b-lg `} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="w-full md:max-w-5xl relative h-full flex flex-col gap-y-2 md:gap-y-6 bg-white border-x border-gray-300">
        <div className="w-full h-8 bg-gradient-to-r from-green-600 via-green-500 to-green-400 ">
          <div
            className="w-28 shrink font-bailoutRegular pl-4 pt-1 text-white cursor-pointer hover:text-white/90"
            onClick={() => navigate('/')}
          >
            BAILOUT
          </div>
        </div>

        {/* NFT LOANS */}
        <div className="max-w-5xl w-full h-full px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center">
            <div className="flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 flex flex-row ">
                🧾 Active Loans
              </h1>
              <p className="hidden lg:block mt-2 text-sm text-gray-700 ">
                A list of all active Bailout loans, including collateral nft and
                outstanding debt.
              </p>
              <p className="hidden md:block lg:hidden mt-2 text-sm text-gray-700 ">
                All active loans belong to wallet
              </p>
            </div>
            <div className="lg:mt-4 sm:mt-0 sm:ml-16 flex-none">
              <div
                className={`${
                  isWalletConnected ? 'custom-button' : 'custom-button-initial'
                }`}
                //className="inline-flex items-center justify-center font-semibold rounded-2xl  bg-white px-[4px] py-[4px]  text-sm text-white shadow-sm focus:outline-none focus:ring-0 sm:w-auto transition-all duration-1000"
              >
                <ConnectButton />
              </div>
            </div>
          </div>
          <div className="mt-4 md:mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="relative inline-block min-w-full py-2 align-middle  md:px-6 lg:px-8  overflow-x-hidden">
                {isLoadingLoans && (
                  <div className="w-full absolute flex flex-row pt-6 pr-10 justify-center  overflow-x-hidden ">
                    {targetWallet && (
                      <svg
                        className="animate-spin h-6 w-6 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </div>
                )}
                <div
                  className={`${
                    isLoadingLoans
                      ? 'opacity-0'
                      : targetWallet
                      ? 'opacity-100'
                      : 'opacity-0'
                  } transition-all duration-1000 overflow-hidden xl:min-w-[801px] w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg`}
                >
                  <table
                    className={`${
                      isLoadingLoans
                        ? 'opacity-0'
                        : targetWallet
                        ? 'opacity-100'
                        : 'opacity-0'
                    } min-w-full divide-y divide-gray-300`}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Liquidation
                        </th>
                        <th
                          scope="col"
                          className="hidden md:block px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Interest
                        </th>
                        <th
                          scope="col"
                          className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Debt
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 md:pl-3 md:pr-4 "
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                      {loans.map((loan) => (
                        <tr key={loan.branchAddress + ':' + loan.loanId}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={getNftImage(loan)}
                                  alt="loan nft"
                                />
                              </div>
                              <div className="ml-4 max-w-[50px] md:max-w-[230px]">
                                <div className="font-medium text-gray-900">
                                  #{loan.nftId}
                                </div>
                                <div className="text-ellipsis text-xs font-mono overflow-hidden text-gray-500">
                                  {loan.nftContract.name} [
                                  {loan.nftContract.symbol}]
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden md:table-cell md:whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                            {loan.timeToLiquidation.displayString}
                          </td>
                          <td className=" md:hidden flex flex-col px-3 py-4 text-sm text-gray-500 ">
                            <div>
                              {' '}
                              {loan.timeToLiquidation.displayString
                                .split(' ')
                                .slice(0, 2)
                                .join(' ')}
                            </div>
                            <div>
                              {loan.timeToLiquidation.displayString
                                .split(' ')
                                .slice(2)
                                .join(' ')}
                            </div>
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-400 ">
                            $
                            {(
                              (Number(loan.debt.current) -
                                Number(loan.debt.initial)) *
                              ETH_USD_PRICE
                            ).toFixed(2)}
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-500 ">
                            {Number(loan.debt.current).toFixed(4) + ' ETH'}
                          </td>
                          <td className="md:hidden whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-500 ">
                            {Number(loan.debt.current).toFixed(4)}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div
                              onClick={() => {
                                if (!isLoading) {
                                  setIsTxModalOpen(true)
                                  setTxData(loan)
                                }
                              }}
                              className={`${
                                loan.camLiquidate === '0 Days 0 Hours'
                                  ? 'text-red-500 hover:text-red-900 cursor-pointer'
                                  : 'text-green-500 cursor-pointer'
                              }  `}
                            >
                              {isLoading ? 'Loading ...' : 'Repay'}
                              <span className="sr-only">, {loan.loanId}</span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {loans.length === 0 && (
                    <div className="w-full flex justify-center text-gray-400 py-6 border-t border-gray-200">
                      {!isLoadingLoans && 'This wallet has no active loans'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-8 absolute bottom-0  bg-gradient-to-r from-green-600 via-green-500 to-green-600 " />
      </div>
      {/* ////////////// */}
    </div>
  )
}
