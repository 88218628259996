import { ConnectButton } from '@rainbow-me/rainbowkit'

const ETH_USD_PRICE = 1535 // To-do: fetch from API

const LoanTable = (props) => {
  const {
    loans,
    isLoadingLoans,
    targetWallet,
    isWalletConnected,
    setIsTxModalOpen,
    setTxData,
    viewType,
    liquidate,
  } = props

  const getNftImage = (loan) => {
    if (loan.nftMedia) {
      let url = loan.nftMedia[0].thumbnail
        ? loan.nftMedia[0].thumbnail
        : loan.nftMedia[0].gateway

      if (url === 'https://miladymaker.net/milady/5486.png') {
        url =
          'https://lh3.googleusercontent.com/TEuItW8grssxcpbgDDyNxr8JpcaU-eZJyJS0rnoexzRuG1_TayNGIQ52R0DS1StrUQkCakZUmW9SWVrqD2UE_82BJ7JL5NtZ_e0tnA=s0'
      }

      if (url.indexOf('/ipfs/') !== -1) {
        url = 'https://bailout.mypinata.cloud/ipfs/' + url.split('ipfs/')[1]
      }

      return url
    }
  }

  return (
    <div className="flex flex-col max-h-full">
      <div className="max-w-full overflow-x-auto">
        <div className="relative inline-block min-w-full align-middle  overflow-x-hidden">
          {isLoadingLoans && (
            <div className="w-full absolute flex flex-row pt-6 pr-10 justify-center  overflow-x-hidden ">
              {targetWallet && (
                <svg
                  className="animate-spin h-6 w-6 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </div>
          )}
          <div
            className={`${
              isLoadingLoans ? 'opacity-0' : ''
            } transition-all duration-1000 overflow-hidden  w-full  ring-1 ring-black ring-opacity-5 `}
          >
            <table
              className={`${
                isLoadingLoans ? 'opacity-0' : ''
              } min-w-full divide-y divide-gray-200 table-auto`}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Liquidation
                  </th>
                  <th
                    scope="col"
                    className="hidden md:table-cell px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Interest
                  </th>
                  <th
                    scope="col"
                    className="px-2 sm:px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Debt
                  </th>
                  <th scope="col" className="relative py-3.5 md:pl-3 md:pr-4 ">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white overflow-y-scroll ">
                {loans.map((loan) => (
                  <tr key={loan.branchAddress + ':' + loan.loanId}>
                    <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getNftImage(loan)}
                            alt="loan nft"
                          />
                        </div>
                        <div className="ml-4 max-w-[50px] md:max-w-[230px]">
                          <div className="font-medium text-gray-900">
                            #{loan.nftId}
                          </div>
                          <div className="text-ellipsis text-xs font-mono overflow-hidden text-gray-500">
                            {loan.nftContract.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell flex flex-col px-3 py-4 text-sm text-gray-500 justify-center h-full">
                      {loan.timeToLiquidation.displayString !== 'OVERDUE' && (
                        <>
                          <div>
                            {' '}
                            {loan.timeToLiquidation.displayString
                              .split(' ')
                              .slice(0, 2)
                              .join(' ')}
                          </div>
                          <div>
                            {loan.timeToLiquidation.displayString
                              .split(' ')
                              .slice(2)
                              .join(' ')}
                          </div>
                        </>
                      )}
                      {loan.timeToLiquidation.displayString === 'OVERDUE' &&
                        'OVERDUE'}
                    </td>
                    <td className="hidden md:table-cell whitespace-nowrap px-2 sm:px-3 py-4 font-mono text-sm text-gray-400 ">
                      $
                      {(
                        (Number(loan.debt.current) -
                          Number(loan.debt.initial)) *
                        ETH_USD_PRICE
                      ).toFixed(2)}
                    </td>
                    <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-500 ">
                      {Number(loan.debt.current).toFixed(4) + ' ETH'}
                    </td>
                    <td className="md:hidden whitespace-nowrap px-2 sm:px-3 py-4 font-mono text-sm text-gray-500 ">
                      {Number(loan.debt.current).toFixed(4)}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-2 sm:pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div
                        onClick={() => {
                          if (loan.canLiquidate) {
                            liquidate(loan)
                          }
                        }}
                        className={`${
                          loan.canLiquidate === true
                            ? 'text-red-500 hover:text-red-900 cursor-pointer'
                            : 'text-gray-300'
                        }  `}
                      >
                        {loan.canLiquidate === true ? 'Liquidate' : 'Active'}
                        <span className="sr-only">, {loan.loanId}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loans.length === 0 && (
              <div className="w-full flex justify-center text-gray-400 py-6 border-t border-gray-200">
                {!isLoadingLoans && 'No active loans'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanTable
