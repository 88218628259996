import BRANCH_ABI from './assets/abis/Branch'
import CHECKOUT_ABI from './assets/abis/Checkout'
import CHECKOUT_V2_ABI from './assets/abis/Checkout_V2'
import LEHMAN_ABI from './assets/abis/Lehman'
import NFTXVAULT_ABI from './assets/abis/NftxVault'
import PRIVATEBANKING_ABI from './assets/abis/PrivateBanking'
import SUDOPOOLHELPER_ABI from './assets/abis/SudoPoolHelper'
import SUSHISWAP_ABI from './assets/abis/Sushiswap'
import WETH_ABI from './assets/abis/WETH'
import ERC20_ABI from './assets/abis/ERC20'

import TUBBYCATS from './assets/nfts/tubbycats.png'
import MILADIES from './assets/nfts/milady.jpg'
import OTHERDEEDS from './assets/nfts/otherdeeds.jpg'

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
const pudgypenguins = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'
const lilpudgies = '0x524cab2ec69124574082676e6f654a18df49a048'

const CHECKOUT_V2 = '0xF6B2a79A3e2FBAf485C52EDa3F1EA53d04487559'
const PRIVATEBANKING = '0x39778B15Ff2DC05a46B279eBd5b125CDe501A365'

const CHECKOUT = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
const LEHMAN = '0x32cB6aE94cBda6146Bd1797428bA1b65869b9C31'
const SUDOPOOLHELPER = '0x3f25f46bc696e37ad716377b15be16ffb637841d'
const SUSHISWAP = '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f'
const WETH = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'

const ABI = {
  BRANCH: BRANCH_ABI,
  CHECKOUT: CHECKOUT_ABI,
  CHECKOUT_V2: CHECKOUT_V2_ABI,
  LEHMAN: LEHMAN_ABI,
  ERC20: ERC20_ABI,
  NFTXVAULT: NFTXVAULT_ABI,
  PRIVATEBANKING: PRIVATEBANKING_ABI,
  SUDOPOOLHELPER: SUDOPOOLHELPER_ABI,
  SUSHISWAP: SUSHISWAP_ABI,
  WETH: WETH_ABI,
}

const API = {
  BASE_URL: 'https://us-central1-bailout-cee17.cloudfunctions.net',
}

const ALCHEMY_URL =
  'https://eth-mainnet.g.alchemy.com/v2/-icGAQDy6THpkE5XniTZih8bKwCgVmFK'

const API_ENDPOINTS = {
  GET_SUDO_COLLECTION:
    'https://us-central1-bailout-cee17.cloudfunctions.net/getSudoswapCollection',
}

const BRANCHES = {
  ['0x82c887e263985CcF01705022E46C1B3ad1e5a8Ff']: {
    nft: tubbycats,
    maxLoan: 0.08,
  },
  ['0x5Af0D9827E0c53E4799BB226655A1de152A425a5']: {
    nft: miladys,
    maxLoan: 0.3,
  },
  ['0x6AEe961e938C9743D1E5F72d2954924377e389F1']: {
    nft: otherdeeds,
    maxLoan: 1.25,
  },
}

const CONTRACTS = {
  LEHMAN: LEHMAN,
  CHECKOUT: CHECKOUT,
  CHECKOUT_V2: CHECKOUT_V2,
  SUDOPOOLHELPER: SUDOPOOLHELPER,
  SUSHISWAP: SUSHISWAP,
  PRIVATEBANKING: PRIVATEBANKING,
  WETH: WETH,
}

const COLLECTIONS = {
  [tubbycats]: {
    image: TUBBYCATS,
    name: 'Tubby Cats',
    maxLoan: 0.08,
    branch: '0x82c887e263985CcF01705022E46C1B3ad1e5a8Ff',
  },
  [miladys]: {
    image: MILADIES,
    name: 'Milady Maker',
    maxLoan: 0.3,
    branch: '0x5Af0D9827E0c53E4799BB226655A1de152A425a5',
  },
  [otherdeeds]: {
    image: OTHERDEEDS,
    name: 'Otherdeeds',
    maxLoan: 1.25,
    branch: '0x6AEe961e938C9743D1E5F72d2954924377e389F1',
  },
  [pudgypenguins]: {
    maxLoan: 2.5,
    branch: '0x73D843995eEF3A2A8296d756cef488eaf81Bde62',
  },
  [lilpudgies]: {
    address: '0x76A8402C0754431ae0B1AEdA8FEdB880fB57507a',
    maxLoan: '0.15',
    name: 'Lil Pudgies',
  },
}

const DEPRECATED_BRANCHES = [
  '0xc48cb41Fc7240a76D3D35558fd707E45Cc5C2eCA',
  '0x9FED203c39616cc93AE44b134C1Cc68f2a8EC46A',
]

const MARKETPLACES = {
  NFTX: {
    [tubbycats]: '0x0e050b2b7adb2cae5e8593e280ed5582953f9ad2',
    [miladys]: '0x227c7df69d3ed1ae7574a1a7685fded90292eb48',
    [otherdeeds]: '0xfc0247cdaabc166423915077b666fa3bb9d1ee4d',
    [pudgypenguins]: '0xabea7663c472648d674bd3403d94c858dfeef728',
  },
}

export {
  ABI,
  API,
  ALCHEMY_URL,
  API_ENDPOINTS,
  BRANCHES,
  CONTRACTS,
  COLLECTIONS,
  DEPRECATED_BRANCHES,
  MARKETPLACES,
}
