import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { ConnectButton, useAccountModal } from '@rainbow-me/rainbowkit'
import axios from 'axios'
import { ethers } from 'ethers'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { CONTRACTS, ABI } from '../config.js'
import BranchTable from '../components/BranchTable'
import LoanTable from '../components/LoanTable'
import {
  getBranchData,
  getUserLPTokenBalanceAndApprovals,
  getBranchSupply,
} from '../helpers/bailout'

const getNftImage = (loan) => {
  if (loan.nftMedia) {
    let url = loan.nftMedia[0].thumbnail
      ? loan.nftMedia[0].thumbnail
      : loan.nftMedia[0].gateway

    if (url === 'https://miladymaker.net/milady/5486.png') {
      url =
        'https://lh3.googleusercontent.com/TEuItW8grssxcpbgDDyNxr8JpcaU-eZJyJS0rnoexzRuG1_TayNGIQ52R0DS1StrUQkCakZUmW9SWVrqD2UE_82BJ7JL5NtZ_e0tnA=s0'
    }
    if (url.indexOf('/ipfs/') !== -1) {
      url = 'https://bailout.mypinata.cloud/ipfs/' + url.split('ipfs/')[1]
    }

    return url
  }
}

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
const pudgypenguins = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'

const BailoutPools = {
  [tubbycats]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.08',
    name: 'Tubbycats',
  },
  [miladys]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.30',
    name: 'Milady',
  },
  [otherdeeds]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '1.50',
    name: 'Otherdeeds',
  },
  [pudgypenguins]: {
    address: '0x73D843995eEF3A2A8296d756cef488eaf81Bde62',
    maxLoan: '2.50',
    name: 'Pudgies',
  },
}

export default function Branch_V2({ analytics }) {
  let navigate = useNavigate()
  let { address } = useParams()
  address = address.toLowerCase()
  if (
    address === 'pudgypenguins' ||
    address === 'pudgy-penguins' ||
    address === '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'
  ) {
    address = '0x73D843995eEF3A2A8296d756cef488eaf81Bde62'
  } else if (
    address === 'tubbycats' ||
    address === 'tubby-cats' ||
    address === '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
  ) {
    address = '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff'
  }

  console.log('branch address', address)

  const signer = useSigner()
  const { openAccountModal } = useAccountModal()
  const { address: wallet, isConnected, isDisconnected } = useAccount()

  const {
    data: walletBalance,
    isError,
    isLoading: isLoadingBalance,
    isFetched,
  } = useBalance({
    addressOrName: wallet,
  })
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [isLoadingLpToken, setIsLoadingLpToken] = useState(true)
  const [lpTokenBalance, setLpTokenBalance] = useState(0)
  const [lpTokenApproval, setLpTokenApproval] = useState(0)
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [isLoading, setIsLoading] = useState(true)
  const [metadata, setMetadata] = useState({})
  const [branch, setBranchData] = useState({})
  const [loans, setLoans] = useState([])
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [depositAmount, setDepositAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()

  const [depositError, setDepositError] = useState('')
  const [withdrawError, setWithdrawError] = useState('')

  const [txType, setTxType] = useState('')
  const [isSendingTx, setIsSendingTx] = useState(false)
  const [isMiningTx, setIsMiningTx] = useState(false)
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //

  const [isLiquidating, setIsLiquidating] = useState(false)
  const [liquidationData, setLiquidationData] = useState({})

  const [isApproving, setIsApproving] = useState(false)
  const [isMiningApproval, setIsMiningApproval] = useState(false)

  const [liquidationType, setLiquidationType] = useState('')
  const [isSendingLiquidation, setIsSendingLiquidation] = useState(false)
  const [isMiningLiquidation, setIsMiningLiquidation] = useState(false)
  const [isLiquidated, setIsLiquidated] = useState(false)

  useEffect(() => {
    if (isDisconnected) {
      setIsLoadingLpToken(true)
      setLpTokenBalance(0)
      setLpTokenApproval(0)
      setDepositAmount()
      setWithdrawAmount()
    }
  }, [isDisconnected])

  useEffect(() => {
    if (!address) {
      navigate('/')
    } else {
      {
        getBranchData(address)
          .then((data) => {
            axios
              .get(
                `https://us-central1-bailout-cee17.cloudfunctions.net/getCollectionMetadata?nft=${data.nft}`,
              )
              .then((response) => {
                setMetadata(response.data)
                setBranchData(data)
                setLoans(data.loans)
                setIsLoading(false)
              })
          })
          .catch((error) => {
            console.log(error)
            navigate('/')
          })
      }
    }
  }, [])

  useEffect(() => {
    if (branch && wallet) {
      try {
        getUserLPTokenBalanceAndApprovals(wallet, branch.lpToken).then(
          ({ balance, approval }) => {
            setIsLoadingLpToken(false)
            setLpTokenBalance(balance)
            setLpTokenApproval(approval)
          },
        )
      } catch (error) {
        console.log(error)
      }
    }
  }, [branch, wallet])

  const approve = () => {
    if (
      !isApproving &&
      !isMiningApproval &&
      !isSendingLiquidation &&
      !isMiningLiquidation
    ) {
      setIsApproving(true)
      const LP_TOKEN = new ethers.Contract(
        branch.lpToken,
        ABI.ERC20,
        signer.data,
      )

      LP_TOKEN.approve(CONTRACTS.PRIVATEBANKING, ethers.constants.MaxUint256)
        .then((tx) => {
          setIsApproving(false)
          setIsMiningApproval(true)
          tx.wait()
            .then(() => {
              setIsApproving(false)
              setIsMiningApproval(false)
              setLpTokenApproval(Number(ethers.constants.MaxUint256.toString()))
            })
            .catch((error) => {
              console.log(error)
              setIsApproving(false)
              setIsMiningApproval(false)
            })
        })
        .catch((error) => {
          console.log(error)
          setIsApproving(false)
        })
    }
  }

  const liquidate = (type) => {
    if (
      liquidationType === '' &&
      !isSendingLiquidation &&
      !isMiningLiquidation &&
      !isLiquidated
    ) {
      const PRIVATE_BANKING = new ethers.Contract(
        CONTRACTS.PRIVATEBANKING,
        ABI.PRIVATEBANKING,
        signer.data,
      )

      let EthDebt = ethers.utils.parseEther(liquidationData.debt.current)
      EthDebt = EthDebt.add(EthDebt.div(100))

      let amountToSend = EthDebt

      if (type !== 'eth') {
        if (ethers.BigNumber.from(lpTokenBalance.toString()).lt(EthDebt)) {
          amountToSend = amountToSend.sub(lpTokenBalance.toString())
        } else {
          amountToSend = 0
        }
      }

      if (walletBalance.value.gte(amountToSend)) {
        setLiquidationType(type)
        setIsSendingLiquidation(true)

        console.log('private_banking address', address)
        console.log('private_banking wallet', wallet)
        console.log('private_banking loanId', liquidationData.loanId)

        PRIVATE_BANKING.manipulateMarketAndEvict(
          address,
          wallet,
          liquidationData.loanId,
          { value: amountToSend, gasLimit: 400000 },
        )
          .then((tx) => {
            setIsSendingLiquidation(false)
            setIsMiningLiquidation(true)
            tx.wait()
              .then(() => {
                setIsLiquidated(true)
                setIsMiningLiquidation(false)
                setLiquidationType('')

                getBranchData(address).then((data) => {
                  axios
                    .get(
                      `https://us-central1-bailout-cee17.cloudfunctions.net/getCollectionMetadata?nft=${data.nft}`,
                    )
                    .then((response) => {
                      setMetadata(response.data)
                      setBranchData(data)
                      setLoans(data.loans)
                      setIsLoading(false)
                    })
                })
                getUserLPTokenBalanceAndApprovals(wallet, branch.lpToken).then(
                  ({ balance, approval }) => {
                    setIsLoadingLpToken(false)
                    setLpTokenBalance(balance)
                    setLpTokenApproval(approval)
                  },
                )
              })
              .catch((error) => {
                console.log(error)

                setIsLiquidated(false)
                setIsMiningLiquidation(false)
                setLiquidationType('')
              })
          })
          .catch((error) => {
            console.log(error)
            setIsSendingLiquidation(false)
            setLiquidationType('')
          })
      }
    }
  }

  const depositLiquidity = () => {
    if (
      !isSendingTx &&
      !isMiningTx &&
      depositAmount <= Number(ethers.utils.formatEther(walletBalance.value))
    ) {
      setTxType('deposit')
      setIsSendingTx(true)
      const PRIVATE_BANKING = new ethers.Contract(
        CONTRACTS.PRIVATEBANKING,
        ABI.PRIVATEBANKING,
        signer.data,
      )
      PRIVATE_BANKING.underwriteAndOfferSubprimeMortgage(address, wallet, {
        value: ethers.utils.parseEther(depositAmount),
        gasLimit: 300000,
      })
        .then((txResponse) => {
          setIsSendingTx(false)
          setIsMiningTx(true)
          txResponse
            .wait()
            .then(() => {
              Promise.all([
                getBranchSupply(address),
                getUserLPTokenBalanceAndApprovals(wallet, branch.lpToken),
              ]).then((data) => {
                const { totalDebt, totalSupply, totalUnderlying } = data[0]
                const { balance, approval } = data[1]
                setTxType('')
                setIsMiningTx(false)
                setDepositAmount('')
                setDepositError('')
                setLpTokenBalance(balance)
                setLpTokenApproval(approval)
                setBranchData((current) => {
                  return {
                    ...current,
                    totalDebt: totalDebt,
                    totalSupply: totalSupply,
                    totalUnderlying: totalUnderlying,
                  }
                })
              })
            })
            .catch((error) => {
              setTxType('')
              setIsMiningTx(false)
              setDepositAmount('')
              setDepositError('Transaction failed')
            })
        })
        .catch((error) => {
          setTxType('')
          setIsSendingTx(false)
        })
    }
  }

  const withdrawLiquidty = () => {
    if (
      !isSendingTx &&
      !isMiningTx &&
      !isLoadingLpToken &&
      withdrawAmount <=
        Number(ethers.utils.formatEther(lpTokenBalance.toString()))
    ) {
      setTxType('withdraw')
      setIsSendingTx(true)
      const BRANCH = new ethers.Contract(address, ABI.BRANCH, signer.data)
      BRANCH.regulateMarket(wallet, ethers.utils.parseEther(withdrawAmount), {
        gasLimit: 150000,
      })
        .then((txResponse) => {
          setIsSendingTx(false)
          setIsMiningTx(true)
          txResponse
            .wait()
            .then(() => {
              Promise.all([
                getBranchSupply(address),
                getUserLPTokenBalanceAndApprovals(wallet, branch.lpToken),
              ]).then((data) => {
                const { totalDebt, totalSupply, totalUnderlying } = data[0]
                const { balance, approval } = data[1]
                setTxType('')
                setIsMiningTx(false)
                setWithdrawAmount('')
                setWithdrawError('')
                setLpTokenBalance(balance)
                setLpTokenApproval(approval)
                setBranchData((current) => {
                  return {
                    ...current,
                    totalDebt: totalDebt,
                    totalSupply: totalSupply,
                    totalUnderlying: totalUnderlying,
                  }
                })
              })
            })
            .catch((error) => {
              setTxType('')
              setIsMiningTx(false)
              setWithdrawAmount('')
              setWithdrawError('Transaction failed')
            })
        })
        .catch((error) => {
          setTxType('')
          setIsSendingTx(false)
        })
    }
  }

  console.log('lpTokenBalance', lpTokenBalance.toString())

  console.log('liquidationData', liquidationData)

  const getLiquidationPrices = (liquidationData) => {
    let costInEthOnly = Number(
      liquidationData.debt ? liquidationData?.debt?.current : 0,
    ).toFixed(3)
  }

  return (
    <>
      <div className="min-h-full bg-gray-50">
        <Helmet>
          <title>Bailout - Branch: {address}</title>
        </Helmet>
        <main className="py-10">
          {/* Page header */}
          <div className="mx-auto max-w-3xl lg:max-w-5xl sm:px-6">
            <div className="w-full bg-gradient-to-r from-green-600 via-green-500 to-green-400 ">
              <div
                className="w-28 shrink font-bailoutRegular pl-4 pb-1 pt-2 text-white cursor-pointer hover:text-white/90"
                onClick={() => navigate('/')}
              >
                BAILOUT
              </div>
            </div>
          </div>

          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3">
            <section
              aria-labelledby="lp-widget"
              className="lg:col-span-1 lg:col-start-1"
            >
              {/*
                <div className="mb-4 px-4 py-4 w-full bg-white border border-gray-200">
                  <img src={BAILOUT_PENGUIN_POOL} />
                </div>
              */}

              <div className="bg-white border border-gray-200 ">
                <div className="relative w-full h-full">
                  <div
                    className={`${
                      !isConnected ? 'hidden' : ''
                    } border-b border-gray-200 py-4 px-6 justify-between w-full flex flex-row  items-center`}
                  >
                    <div className="border-green-600 font-inter font-semibold text-green-600 items-center flex flex-row">
                      <div
                        onClick={() => openAccountModal()}
                        className="flex flex-col leading-tight font-mono group w-full "
                      >
                        <div className="w-full inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                          {Number(
                            ethers.utils
                              .formatEther(
                                isFetched ? walletBalance.value : '0',
                              )
                              .toString(),
                          ).toFixed(3) + ' ETH'}
                        </div>
                        <div className="inline-flex leading-tight text-gray-500 text-sm font-medium ">{`${
                          wallet ? wallet.slice(0, 17) : ''
                        }...`}</div>
                      </div>
                    </div>
                    <div
                      onClick={() => openAccountModal()}
                      className="hidden sm:inline-flex h-full  items-center border-l border-gray-300 pl-3 cursor-pointer text-gray-500 hover:opacity-80 font-medium text-sm"
                    >
                      Wallet
                    </div>
                  </div>

                  <div
                    className={`${
                      isConnected ? 'hidden' : ''
                    } absolute flex flex-col justify-center h-full w-full z-[100] backdrop-blur-md  px-4 sm:px-6`}
                  >
                    <div className="justify-center  flex flex-row ">
                      <ConnectButton />
                    </div>
                  </div>
                  <div className="flex flex-col px-4 pt-3 pb-5 sm:px-6">
                    <h2
                      id="lp-widget-deposit"
                      className="text-lg font-medium text-gray-900"
                    >
                      Deposit Liquidity
                    </h2>
                    <p className="text-sm text-gray-500">
                      Increments of{' '}
                      <div className="font-medium text-gray-600 inline-flex">
                        2.50 ETH
                      </div>{' '}
                      gives enough LP tokens to liquidate defaulted nfts.
                    </p>
                    <div
                      className={`${
                        depositError
                          ? 'text-yellow-500 text-sm w-full flex flex-row justify-between'
                          : 'hidden'
                      }`}
                    >
                      <div className="inline-flex">{depositError}</div>
                      {depositError.indexOf('Insufficient') > -1 && (
                        <div
                          onClick={() => {
                            setDepositError('')
                            setDepositAmount(
                              Number(
                                ethers.utils.formatEther(walletBalance.value),
                              ),
                            )
                          }}
                          className="inline-flex pl-1 text-yellow-500 underline cursor-pointer"
                        >
                          set max
                        </div>
                      )}
                    </div>
                    <div
                      className={`${
                        depositError ? 'pt-0' : 'pt-2'
                      } w-full flex flex-row `}
                    >
                      <div className="relative w-full flex flex-row justify-between rounded-t-sm shadow-inner bg-gray-100">
                        <input
                          className="overflox-scroll-x w-full bg-transparent  placeholder:text-gray-300 text-xl font-mono px-3 py-2 text-gray-500 focus:ring-green-400 focus:outline-none focus:ring-0"
                          placeholder="2.50"
                          onChange={(e) => {
                            let amount = e.target.value

                            setDepositAmount(amount)
                            if (
                              amount >
                              Number(
                                ethers.utils.formatEther(walletBalance.value),
                              )
                            ) {
                              setDepositError('Insufficient balance.')
                            } else {
                              setDepositError('')
                            }
                          }}
                          value={depositAmount}
                        />
                        <div className="right-0 pr-2 py-2 text-xl font-mono text-gray-300  ">
                          <div className="bg-gray-100">ETH</div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div
                        onClick={() => depositLiquidity()}
                        className="w-full flex flex-row justify-center px-2 py-2 border cursor-pointer group"
                      >
                        {txType === 'deposit' && (
                          <div
                            className={`${
                              isSendingTx ? 'justify-center' : 'justify-between'
                            } w-full flex flex-row items-center px-2 text-gray-400`}
                          >
                            {isSendingTx && (
                              <div className="text-gray-400">
                                Approve transaction in wallet
                              </div>
                            )}
                            {isMiningTx && (
                              <div className="text-gray-400">
                                Mining transaction...
                              </div>
                            )}

                            {isMiningTx && (
                              <svg
                                className={`animate-spin h-6 w-6 text-gray-400`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                          </div>
                        )}
                        {txType !== 'deposit' && (
                          <div className="group-hover:text-green-500">
                            Deposit
                          </div>
                        )}
                      </div>
                    </div>

                    <h2
                      id="lp-widget-deposit"
                      className="text-lg font-medium text-gray-900 pt-4"
                    >
                      Withdraw Liquidity
                    </h2>
                    <p className="pb-2 text-sm text-gray-500">
                      Bailout lp tokens function like cTokens, with their value
                      increasing over time.
                    </p>
                    <div className="w-full flex flex-row justify-between items-center border border-gray-100">
                      <div className="w-full px-2 py-2 leading-tight text-sm text-gray-500 font-normal ">
                        Current LP Balance:
                      </div>
                      <div className="bg-gray-100 h-full gap-x-1 font-mono px-2 py-2 pt-[0.6rem] inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                        <div className="inline-flex text-gray-500">
                          {' '}
                          {Number(
                            ethers.utils
                              .formatEther(
                                !isLoadingLpToken
                                  ? lpTokenBalance.toString()
                                  : '0',
                              )
                              .toString(),
                          ).toFixed(3)}
                        </div>
                        <div className="inline-flex text-gray-500">aETH</div>
                      </div>
                    </div>
                    <div
                      className={`${
                        withdrawError
                          ? 'text-yellow-500 text-sm w-full flex flex-row justify-between'
                          : 'hidden'
                      }`}
                    >
                      <div className="inline-flex">{withdrawError}</div>
                      {withdrawError.indexOf('Insufficient') > -1 && (
                        <div
                          onClick={() => {
                            setWithdrawError('')
                            setWithdrawAmount(
                              ethers.utils
                                .formatEther(lpTokenBalance.toString())
                                .toString(),
                            )
                          }}
                          className="inline-flex underline pl-1 text-yellow-500 cursor-pointer"
                        >
                          set max
                        </div>
                      )}{' '}
                    </div>
                    <div
                      className={`${
                        withdrawError ? 'pt-0' : 'pt-2'
                      } w-full flex flex-row `}
                    >
                      <div className="relative w-full flex flex-row justify-between rounded-t-sm shadow-inner bg-gray-100">
                        <input
                          className="w-full rounded-t-sm bg-transparent placeholder:text-gray-300 text-xl font-mono px-3 py-2 text-gray-500 focus:outline-none focus:ring-0"
                          placeholder={Number(
                            ethers.utils
                              .formatEther(lpTokenBalance.toString())
                              .toString(),
                          ).toFixed(2)}
                          onChange={(e) => {
                            let amount = e.target.value

                            setWithdrawAmount(amount)
                            if (
                              amount >
                              Number(
                                ethers.utils.formatEther(
                                  lpTokenBalance.toString(),
                                ),
                              )
                            ) {
                              setWithdrawError('Insufficient balance.')
                            } else {
                              setWithdrawError('')
                            }
                          }}
                          value={withdrawAmount}
                        />
                        <div className="right-0 pr-2 py-2 text-xl font-mono text-gray-300  ">
                          <div className="bg-gray-100">ETH</div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div
                        onClick={() => withdrawLiquidty()}
                        className="w-full flex flex-row justify-center px-2 py-2 border cursor-pointer group"
                      >
                        {txType === 'withdraw' && (
                          <div
                            className={`${
                              isSendingTx ? 'justify-center' : 'justify-between'
                            } w-full flex flex-row items-center px-2 text-gray-400`}
                          >
                            {isSendingTx && (
                              <div className="text-gray-400">
                                Approve transaction in wallet
                              </div>
                            )}
                            {isMiningTx && (
                              <div className="text-gray-400">
                                Mining transaction...
                              </div>
                            )}

                            {isMiningTx && (
                              <svg
                                className={`animate-spin h-6 w-6 text-gray-400`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                          </div>
                        )}
                        {txType !== 'withdraw' && (
                          <div className="group-hover:text-red-500">
                            Withdraw
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${!isConnected ? 'hidden' : ''} mt-8`}>
                <div className="Weth-helper"></div>
              </div>
            </section>

            <div className="space-y-6 lg:col-span-2 lg:col-start-2">
              {/* Description list*/}
              <section
                aria-labelledby="branch-and-loans-overview"
                className="sm:gap-y-4 md:gap-y-8 flex flex-col"
              >
                <div>
                  <div className="w-full flex flex-row justify-center text-gray-900 rounded-t-lg border-x border-t border-gray-200 py-2 bg-white">
                    Branch Overview
                  </div>
                  <div className="border border-gray-200">
                    <BranchTable
                      branches={isLoading ? [] : [{ branch, metadata }]}
                    />
                  </div>
                </div>

                <div className={`${isLiquidating ? 'hidden' : ''}`}>
                  <div className="w-full flex flex-row justify-center text-gray-900 rounded-t-lg border-x border-t border-gray-200 py-2 bg-white">
                    All Loans
                  </div>
                  <div className="border border-gray-200">
                    <LoanTable
                      isLoadingLoans={isLoading}
                      loans={loans}
                      targetWallet=""
                      liquidate={(loan) => {
                        setIsLiquidating(true)
                        setLiquidationData(loan)
                      }}
                    />
                  </div>
                </div>

                <div className={`${isLiquidating ? '' : 'hidden'}`}>
                  <div className="w-full grid grid-cols-3 text-gray-900 rounded-t-lg border-x border-t border-gray-200 py-2 bg-white">
                    <div
                      onClick={() => {
                        setIsLiquidating(false)
                        setLiquidationData({})
                      }}
                      className="px-4 col-span-1 w-full flex flex-row justify-start"
                    >
                      <div className="cursor-pointer">{'< Back'}</div>
                    </div>
                    <div className="col-span-1 w-full flex flex-row justify-center">
                      Liquidation
                    </div>
                    <div className="col-span-1 w-full flex flex-row justify-end"></div>
                  </div>
                  <div className="bg-white border border-gray-200">
                    <div className="w-full grid grid-cols-3 divide-x">
                      <div className="col-span-1 w-full flex flex-row justify-center items-center px-4 py-4">
                        <div className="rounded-t-md shadow ">
                          <img
                            src={getNftImage(liquidationData)}
                            className="h-32 w-32"
                          />
                          <div className="flex flex-row justify-center bg-white">
                            Token #{liquidationData?.nftId}
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          isLiquidated ? '' : 'hidden'
                        } col-span-2 w-full flex flex-row justify-center items-center px-4 py-4`}
                      >
                        <div className="flex flex-col">
                          <h2
                            id="lp-widget-deposit"
                            className="text-lg font-medium text-gray-900 "
                          >
                            Nft Liquidated
                          </h2>
                          <p className="pb-2 text-sm text-gray-500">
                            Congratulations you have liquidated nft id #
                            {liquidationData?.nftId}
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          isLiquidated ? 'hidden' : ''
                        } col-span-1 w-full flex flex-row justify-center px-4 py-4`}
                      >
                        <div className="w-full h-full flex flex-col">
                          <div className="flex flex-col">
                            <h2
                              id="lp-widget-deposit"
                              className="text-lg font-medium text-gray-900 "
                            >
                              With LP Tokens
                            </h2>
                            <p className="pb-2 text-sm text-gray-500">
                              Use your LP tokens directly to liquidate this
                              loan.
                            </p>
                          </div>
                          <div className="w-full h-full flex flex-col gap-y-2">
                            <div className="flex flex-row items-center border border-gray-100">
                              <div className="px-2 py-2 leading-tight text-sm text-gray-500 font-normal ">
                                Cost:
                              </div>
                              <div className="bg-gray-100 grow justify-end h-full gap-x-1 font-mono px-2 py-1 inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                                <div className="w-full h-full flex flex-col items-end">
                                  <div className="flex flex-row gap-x-1">
                                    <div className="inline-flex text-gray-500">
                                      {' '}
                                      {Number(
                                        Number(
                                          liquidationData.debt
                                            ? liquidationData?.debt?.current
                                            : 0,
                                        ) -
                                          Number(
                                            ethers.utils
                                              .formatEther(
                                                !isLoadingLpToken
                                                  ? lpTokenBalance.toString()
                                                  : '0',
                                              )
                                              .toString(),
                                          ),
                                      ).toFixed(3)}
                                    </div>
                                    <div className="inline-flex text-gray-500">
                                      {`‎ ETH`}
                                    </div>
                                  </div>
                                  <div className="flex flex-row gap-x-1">
                                    <div className="inline-flex text-gray-500">
                                      +
                                    </div>{' '}
                                    <div className="inline-flex text-gray-500 ">
                                      {' '}
                                      {Number(
                                        ethers.utils
                                          .formatEther(
                                            !isLoadingLpToken
                                              ? lpTokenBalance.toString()
                                              : '0',
                                          )
                                          .toString(),
                                      ).toFixed(3)}
                                    </div>
                                    <div className="inline-flex text-gray-500">
                                      aETH
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                if (
                                  ethers.BigNumber.from(
                                    lpTokenApproval.toString(),
                                  ).lt(lpTokenBalance.toString())
                                ) {
                                  approve()
                                } else {
                                  liquidate('lp')
                                }
                              }}
                              className={` py-1 w-full flex flex-row items-center border border-gray-200 cursor-pointer hover:text-green-500`}
                            >
                              <div
                                className={`${
                                  Boolean(
                                    isMiningApproval ||
                                      Boolean(
                                        liquidationType === 'lp' &&
                                          isMiningLiquidation,
                                      ),
                                  )
                                    ? 'justify-between'
                                    : 'justify-center'
                                } w-full flex flex-row items-center px-2 text-gray-400`}
                              >
                                {Boolean(
                                  isApproving ||
                                    Boolean(
                                      liquidationType === 'lp' &&
                                        isSendingLiquidation,
                                    ),
                                ) && (
                                  <div className="text-gray-400">
                                    Approve tx...
                                  </div>
                                )}
                                {Boolean(
                                  isMiningApproval ||
                                    Boolean(
                                      liquidationType === 'lp' &&
                                        isMiningLiquidation,
                                    ),
                                ) && (
                                  <div className="text-gray-400">
                                    Mining tx...
                                  </div>
                                )}

                                {Boolean(
                                  isMiningApproval ||
                                    Boolean(
                                      liquidationType === 'lp' &&
                                        isMiningLiquidation,
                                    ),
                                ) && (
                                  <svg
                                    className={`animate-spin h-6 w-6 text-gray-400`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                )}
                                {Boolean(
                                  !isApproving &&
                                    !isMiningApproval &&
                                    liquidationType !== 'lp',
                                ) && (
                                  <div className="w-full flex flex-row justify-center px-2 text-gray-900 hover:text-green-500">
                                    {Number(
                                      Number(
                                        ethers.utils
                                          .formatEther(
                                            !isLoadingLpToken
                                              ? lpTokenApproval.toString()
                                              : '0',
                                          )
                                          .toString(),
                                      ) -
                                        Number(
                                          ethers.utils
                                            .formatEther(
                                              !isLoadingLpToken
                                                ? lpTokenBalance.toString()
                                                : '0',
                                            )
                                            .toString(),
                                        ),
                                    ) > 0
                                      ? 'Liquidate'
                                      : 'Approve'}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          isLiquidated ? 'hidden' : ''
                        } col-span-1 w-full flex flex-row justify-center items-center px-4 py-4`}
                      >
                        <div className="w-full h-full flex flex-col">
                          <div className="flex flex-col">
                            <h2
                              id="lp-widget-deposit"
                              className="text-lg font-medium text-gray-900 "
                            >
                              With ETH
                            </h2>
                            <p className="pb-2 text-sm text-gray-500">
                              Use only ETH to liquidate this loan.
                            </p>
                          </div>
                          <div className="w-full h-full flex flex-col gap-y-2">
                            <div className="flex flex-row items-center border border-gray-100">
                              <div className="px-2 py-2 leading-tight text-sm text-gray-500 font-normal ">
                                Cost:
                              </div>
                              <div className="bg-gray-100 grow justify-end h-full gap-x-1 font-mono px-2 py-1 inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                                <div className="w-full h-full flex flex-col items-end">
                                  <div className="flex flex-row gap-x-1">
                                    <div className="inline-flex text-gray-500">
                                      {' '}
                                      {Number(
                                        liquidationData.debt
                                          ? liquidationData?.debt?.current
                                          : 0,
                                      ).toFixed(3)}
                                    </div>
                                    <div className="inline-flex text-gray-500">
                                      {`‎ ETH`}
                                    </div>
                                  </div>
                                  <div className="flex flex-row gap-x-1 line-through text-gray-300">
                                    <div className="inline-flex text-gray-300">
                                      +
                                    </div>{' '}
                                    <div className="inline-flex text-gray-300 ">
                                      {' '}
                                      {Number(
                                        ethers.utils
                                          .formatEther(
                                            !isLoadingLpToken
                                              ? lpTokenBalance.toString()
                                              : '0',
                                          )
                                          .toString(),
                                      ).toFixed(3)}
                                    </div>
                                    <div className="inline-flex text-gray-300">
                                      aETH
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              onClick={() => liquidate('eth')}
                              className="py-1 w-full flex flex-row justify-center items-center border border-gray-200 cursor-pointer hover:text-green-500"
                            >
                              <div
                                className={`${
                                  Boolean(
                                    Boolean(
                                      liquidationType === 'eth' &&
                                        isMiningLiquidation,
                                    ),
                                  )
                                    ? 'justify-between'
                                    : 'justify-center'
                                } w-full flex flex-row items-center px-2 text-gray-400`}
                              >
                                {Boolean(
                                  Boolean(
                                    liquidationType === 'eth' &&
                                      isSendingLiquidation,
                                  ),
                                ) && (
                                  <div className="text-gray-400">
                                    Approve tx...
                                  </div>
                                )}
                                {Boolean(
                                  Boolean(
                                    liquidationType === 'eth' &&
                                      isMiningLiquidation,
                                  ),
                                ) && (
                                  <div className="text-gray-400">
                                    Mining tx...
                                  </div>
                                )}

                                {Boolean(
                                  Boolean(
                                    liquidationType === 'eth' &&
                                      isMiningLiquidation,
                                  ),
                                ) && (
                                  <svg
                                    className={`animate-spin h-6 w-6 text-gray-400`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                )}
                                {Boolean(
                                  Boolean(liquidationType !== 'eth'),
                                ) && (
                                  <div className="w-full flex flex-row justify-center px-2 text-gray-900 hover:text-green-500">
                                    Liquidate
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
