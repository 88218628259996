module.exports = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_pool',
        type: 'address',
      },
    ],
    name: '_getPoolData',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'pool',
            type: 'address',
          },
          {
            internalType: 'enum ISudoPool.PoolType',
            name: 'poolType',
            type: 'uint8',
          },
          {
            internalType: 'enum ISudoPool.PairVariant',
            name: 'pairVariant',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'bondingCurve',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'assetRecipient',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nft',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'nftsAmount',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'buyCost',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'sellCost',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'canBuyNftFromPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'canSellNftToPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'hasError',
            type: 'bool',
          },
        ],
        internalType: 'struct SudoPoolHelper.PoolData',
        name: 'poolData',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_pool',
        type: 'address',
      },
    ],
    name: 'getPool',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'pool',
            type: 'address',
          },
          {
            internalType: 'enum ISudoPool.PoolType',
            name: 'poolType',
            type: 'uint8',
          },
          {
            internalType: 'enum ISudoPool.PairVariant',
            name: 'pairVariant',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'bondingCurve',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'assetRecipient',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nft',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'nftsAmount',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'buyCost',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'sellCost',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'canBuyNftFromPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'canSellNftToPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'hasError',
            type: 'bool',
          },
        ],
        internalType: 'struct SudoPoolHelper.PoolData',
        name: 'pool',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_pools',
        type: 'address[]',
      },
    ],
    name: 'getPools',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'pool',
            type: 'address',
          },
          {
            internalType: 'enum ISudoPool.PoolType',
            name: 'poolType',
            type: 'uint8',
          },
          {
            internalType: 'enum ISudoPool.PairVariant',
            name: 'pairVariant',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'bondingCurve',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'assetRecipient',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nft',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'nftsAmount',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'token',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'tokenAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'buyCost',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'sellCost',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'canBuyNftFromPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'canSellNftToPool',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'hasError',
            type: 'bool',
          },
        ],
        internalType: 'struct SudoPoolHelper.PoolData[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
]
