import { Network, Alchemy } from 'alchemy-sdk'
import { BigNumber, utils } from 'ethers'
import { getNFTXPriceFloor } from './marketplaces'

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'

const tubbycats_nftx = '0x0e050b2b7adb2cae5e8593e280ed5582953f9ad2' // Random = 4%, Target = 6%
const miladys_nftx = '0x227c7df69d3ed1ae7574a1a7685fded90292eb48' // Random = 4%, Target = 6%
const otherdeeds_nftx = '0xfc0247cdaabc166423915077b666fa3bb9d1ee4d' // Random = 2%, Target = 3%

const settings = {
  apiKey: '-icGAQDy6THpkE5XniTZih8bKwCgVmFK',
  network: Network.ETH_MAINNET,
}

const alchemy = new Alchemy(settings)

const hydrateSudoPools = (allPools) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pools = allPools
        .filter(
          (pool) =>
            pool.nftsAmount > 0 &&
            pool.token === '0x0000000000000000000000000000000000000000',
        )
        .sort((a, b) =>
          Number(
            utils
              .formatEther(BigNumber.from(a.buyCost).sub(b.buyCost))
              .toString(),
          ),
        )

      let minNftsToHydrate = 20
      let shouldHydrate = true
      let nftsHydrated = 0

      let hydratedPools = []
      for (let i = 0; i < pools.length; i++) {
        let poolObject = {
          marketplace: 'sudoswap',
          address: pools[i].address,
          isRandom: false,
          buyCost: pools[i].buyCost,
          totalCount: pools[i].nftsAmount,
          isHydrated: false,
        }

        if (shouldHydrate) {
          nftsHydrated += pools[i].nftsAmount
          if (nftsHydrated >= minNftsToHydrate) {
            shouldHydrate = false
          }

          let metadata = await alchemy.nft.getNftsForOwner(pools[i].address, {
            contractAddresses: [pools[i].nft],
            withMetadata: true,
          })
          poolObject['nfts'] = metadata.ownedNfts.map((nft) => {
            return {
              id: nft.tokenId,
              image: nft.media[0].thumbnail || nft.media[0].gateway,
              image_raw: nft.media[0].raw,
            }
          })
          poolObject['isHydrated'] = true
          hydratedPools.push(poolObject)
        } else {
          poolObject['nfts'] = []
          poolObject['isHydrated'] = false
          hydratedPools.push(poolObject)
        }
      }

      resolve(hydratedPools)
    } catch (error) {
      reject(error)
    }
  })
}

const _getNftsForOwner = (owner, nft) => {
  return new Promise((resolve, reject) => {
    alchemy.nft
      .getNftsForOwner(owner, {
        contractAddresses: [nft],
        withMetadata: true,
      })
      .then((result) => {
        resolve({ hasError: false, metadata: result })
      })
      .catch((error) => {
        resolve({ hasEerror: true, metadata: [] })
      })
  })
}

const hydrateSudoPoolsAndNormalize = (allPools, nftAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pools = allPools
        .filter(
          (pool) =>
            pool.nftsAmount > 0 &&
            pool.token === '0x0000000000000000000000000000000000000000',
        )
        .sort((a, b) =>
          Number(
            utils
              .formatEther(BigNumber.from(a.buyCost).sub(b.buyCost))
              .toString(),
          ),
        )

      let minNftsToHydrate = 20
      let shouldHydrate = true
      let nftsHydrated = 0

      let hydratedPools = []

      let responses = await Promise.all(
        pools.map((pool) => _getNftsForOwner(pool.address, pool.nft)),
      )
      responses.forEach((response, i) => {
        const { hasError, metadata } = response

        let poolObject = {
          marketplace: 'sudoswap',
          address: pools[i].address,
          isRandom: false,
          buyCost: pools[i].buyCost,
          totalCount: pools[i].nftsAmount,
          isHydrated: false,
        }

        if (!hasError) {
          poolObject['nfts'] = metadata.ownedNfts.map((nft) => {
            return {
              id: nft.tokenId,
              image: nft.media[0].thumbnail || nft.media[0].gateway,
              image_raw: nft.media[0].raw,
            }
          })
          poolObject['isHydrated'] = true
          hydratedPools.push(poolObject)
          nftsHydrated += pools[i].nftsAmount
        }
      })

      /*
      for (let i = 0; i < pools.length; i++) {
        let poolObject = {
          marketplace: 'sudoswap',
          address: pools[i].address,
          isRandom: false,
          buyCost: pools[i].buyCost,
          totalCount: pools[i].nftsAmount,
          isHydrated: false,
        }

        if (shouldHydrate) {
          if (nftsHydrated >= minNftsToHydrate) {
            //shouldHydrate = false // Temporarily disabled
          }

          let { hasError, metadata } = await _getNftsForOwner(
            pools[i].address,
            pools[i].nft,
          )
          if (!hasError) {
            poolObject['nfts'] = metadata.ownedNfts.map((nft) => {
              return {
                id: nft.tokenId,
                image: nft.media[0].thumbnail || nft.media[0].gateway,
                image_raw: nft.media[0].raw,
              }
            })
            poolObject['isHydrated'] = true
            hydratedPools.push(poolObject)
            nftsHydrated += pools[i].nftsAmount
          }
        } else {
          poolObject['nfts'] = []
          poolObject['isHydrated'] = false
          hydratedPools.push(poolObject)
        }
      }
      */

      let normalizedObjects = []

      hydratedPools.forEach((pool) => {
        pool.nfts.forEach((nft) => {
          normalizedObjects.push({
            id: pool.address + ':' + nft.id,
            tokenSetId: 'token:' + nftAddress + ':' + nft.id,
            kind: 'sudoswap',
            currency: '0x0000000000000000000000000000000000000000',
            currencySymbol: 'ETH',
            nftImage: nft.image || nft.image_raw,
            price: pool.buyCost,
            nftId: nft.id,
            sourceAddress: pool.address,
            sourceName: 'SudoSwap',
            sourceUrl: `https://sudoswap.xyz/#/item/${nftAddress}/${nft.id}`,
          })
        })
      })

      resolve(normalizedObjects)
    } catch (error) {
      reject(error)
    }
  })
}

const getNFTXHydrateAndNormalize = (nftAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { all } = await getNFTXPriceFloor(nftAddress)
      const { randomePrice, targetPrice, address, collectionUrl } = all

      const allNfts = await _getNftsForOwner(address, nftAddress)

      let normalizedObjects = []

      allNfts.ownedNfts.map((nft) => {
        return {
          id: nftAddress + ':' + nft.id,
          tokenSetId: 'token:' + nftAddress + ':' + nft.id,
          kind: 'nftx',
          currency: '0x0000000000000000000000000000000000000000',
          currencySymbol: 'ETH',
          nftImage:
            nft.media[0].thumbnail || nft.media[0].gateway || nft.media[0].raw,
          price: targetPrice,
          nftId: nft.id,
          sourceAddress: address,
          sourceName: 'Nftx',
          sourceUrl: collectionUrl,
        }
      })

      /* Push random normalized object */

      resolve(normalizedObjects)
    } catch (error) {
      reject(error)
    }
  })
}

const getNftsForOwner = (address, nft) => {
  return alchemy.nft.getNftsForOwner(address, {
    contractAddresses: [nft],
    withMetadata: true,
    refreshCache: true,
  })
}

const getNftMetadata = (nftx, sudoswap, nfts) => {
  const formatMetadata = (name, address, marketplace, metadata, isRandom) => {
    //console.log({ name, address, marketplace, metadata, isRandom })
    let buyCost
    let buyCostTarget
    if (name === 'nftx') {
      buyCostTarget = marketplace.target.toString()
      if (isRandom) {
        buyCost = marketplace.random.toString()
      } else {
        buyCost = marketplace.target.toString()
      }
    } else if (name === 'sudo') {
      buyCost = marketplace.buyCost.toString()
      buyCostTarget = marketplace.buyCost.toString()
    }
    return {
      marketplace: name,
      address: address,
      isRandom: isRandom,
      buyCost: buyCost,
      buyCostTarget: buyCostTarget,
      nfts: metadata.ownedNfts
        .map((nft) => {
          return {
            id: nft.tokenId,
            image: nft.media[0].thumbnail || nft.media[0].gateway,
          }
        })
        .slice(0, isRandom ? 1 : metadata.ownedNfts.length),
      totalCount: metadata.totalCount,
    }
  }

  return new Promise(async (resolve, reject) => {
    const SYNC_NFTX = false
    const SYNC_SUDO = true
    const MAX_SUDO_POOLS = 2

    let output = {}

    nfts.forEach((nft) => (output[nft] = []))

    if (SYNC_NFTX) {
      const [tubbycatsNftx, miladiesNftx, otherdeedsNftx] = await Promise.all([
        getNftsForOwner(tubbycats_nftx, tubbycats),
        getNftsForOwner(miladys_nftx, miladys),
        getNftsForOwner(otherdeeds_nftx, otherdeeds),
      ])
      // Random
      output[tubbycats].push(
        formatMetadata(
          'nftx',
          tubbycats_nftx,
          nftx[tubbycats],
          tubbycatsNftx,
          true,
        ),
      )
      output[miladys].push(
        formatMetadata('nftx', miladys_nftx, nftx[miladys], miladiesNftx, true),
      )
      output[otherdeeds].push(
        formatMetadata(
          'nftx',
          otherdeeds_nftx,
          nftx[otherdeeds],
          otherdeedsNftx,
          true,
        ),
      )
      // Target
      output[tubbycats].push(
        formatMetadata(
          'nftx',
          tubbycats_nftx,
          nftx[tubbycats],
          tubbycatsNftx,
          false,
        ),
      )
      output[miladys].push(
        formatMetadata(
          'nftx',
          miladys_nftx,
          nftx[miladys],
          miladiesNftx,
          false,
        ),
      )
      output[otherdeeds].push(
        formatMetadata(
          'nftx',
          otherdeeds_nftx,
          nftx[otherdeeds],
          otherdeedsNftx,
          false,
        ),
      )
    }

    if (SYNC_SUDO) {
      const metadata = await Promise.all(
        nfts.map((nft) =>
          Promise.all(
            sudoswap[nft]
              .slice(0, MAX_SUDO_POOLS)
              .map((v) => getNftsForOwner(v.pool, nft)),
          ),
        ),
      )
      metadata.forEach((meta, index) => {
        meta.forEach((m, i) => {
          output[nfts[index]].push(
            formatMetadata(
              'sudo',
              sudoswap[nfts[index]][i].pool,
              sudoswap[nfts[index]][i],
              m,
              false,
            ),
          )
        })
      })
    }

    for (const nft in output) {
      output[nft] = output[nft].sort((a, b) => a.buyCost - b.buyCost)
    }

    resolve(output)
  })
}

function secondsToDhms(t) {
  t = Number(t)

  var d = Math.floor(t / 86400)
  var h = Math.floor((t % 86400) / 3600)
  var m = Math.floor(((t % 86400) % 3600) / 60)
  var s = Math.floor(((t % 86400) % 3600) % 60)

  var dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : ''
  var hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : ''
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''

  let displayString = ''
  let smallDisplayString = ''
  if (d !== 0) {
    displayString = dDisplay + ' '
    if (hDisplay !== '') {
      displayString += hDisplay
    } else {
      displayString += '0 hours'
    }
    smallDisplayString = dDisplay
  } else if (h !== 0) {
    displayString = hDisplay + ' '
    if (mDisplay !== '') {
      displayString += mDisplay
    } else {
      displayString += '0 minutes'
    }
    smallDisplayString = hDisplay
  } else if (m !== 0) {
    displayString = mDisplay + ' '
    if (sDisplay !== '') {
      displayString += sDisplay
    } else {
      displayString += '0 seconds'
    }
    smallDisplayString = m + ' mins.'
  } else if (s !== 0) {
    displayString = sDisplay
    smallDisplayString = s + ' sec.'
  } else {
    displayString = 'OVERDUE'
    smallDisplayString = 'OVERDUE'
  }
  return {
    days: d,
    hours: h,
    minutes: m,
    seconds: s,
    displayString: displayString,
    smallDisplayString: smallDisplayString,
    fullString: dDisplay + hDisplay + mDisplay + sDisplay,
    duration: t,
  }
}

export {
  getNftMetadata,
  secondsToDhms,
  hydrateSudoPools,
  hydrateSudoPoolsAndNormalize,
}
