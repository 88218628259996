const getNftImage = (loan) => {
  if (loan.nftMedia) {
    let url = loan.nftMedia[0].thumbnail
      ? loan.nftMedia[0].thumbnail
      : loan.nftMedia[0].gateway

    if (url === 'https://miladymaker.net/milady/5486.png') {
      url =
        'https://lh3.googleusercontent.com/TEuItW8grssxcpbgDDyNxr8JpcaU-eZJyJS0rnoexzRuG1_TayNGIQ52R0DS1StrUQkCakZUmW9SWVrqD2UE_82BJ7JL5NtZ_e0tnA=s0'
    }
    return url
  }
}

export default function CollateralCard(props) {
  const { txData } = props
  if (txData !== {}) {
    //console.log(txData)
    return (
      <div className="w-full flex flex-col pb-3 mb-3 ">
        <div className="pb-1 text-lg font-medium leading-6 text-gray-900">
          Your Purchase
        </div>
        <div
          key={txData.branchAddress + ':' + txData.loanId}
          className="w-full flex flex-row items-center justify-between border border-gray-300 rounded-lg shadow"
        >
          <div className="whitespace-nowrap py-2 px-1 text-sm border-r border-gray-300 min-w-[145px] max-w-[145px]">
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={getNftImage(txData)}
                  alt="loan nft"
                />
              </div>
              <div className="ml-2 ">
                <div className="text-gray-900">#{txData.nftId}</div>
                <div className="text-ellipsis text-xs font-mono overflow-hidden text-gray-500">
                  {txData.nftContract.name}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full whitespace-nowrap px-1 py-2  text-sm text-gray-500  ">
            <div className="text-gray-600"> Time left </div>
            <div className="font-mono text-gray-500">
              {txData.timeToLiquidation.smallDisplayString}
            </div>
          </div>
          <div className="flex flex-col whitespace-nowrap px-3 py-2  text-sm text-gray-500 border-l border-gray-300 bg-gray-50 rounded-r-lg shadow-inner">
            <div className="text-gray-600"> Amount </div>
            <div className="font-mono text-gray-700">
              {Number(txData.debt.current).toFixed(4) + ' ETH'}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
