import { ethers } from 'ethers'
import { secondsToDhms } from '../helpers/utils.js'
import axios from 'axios'
import { getNFTXPriceFloor, getSudoPriceFloor } from './marketplaces.js'
import {
  ABI,
  ALCHEMY_URL,
  BRANCHES,
  CONTRACTS,
  COLLECTIONS,
  DEPRECATED_BRANCHES,
} from '../config.js'
import e from 'cors'

const PRECISION_1E27 = '1000000000000000000000000000'

const provider = new ethers.providers.JsonRpcProvider(ALCHEMY_URL)

const getBranches = () => {
  return new Promise(async (resolve, reject) => {
    await provider._ready()

    const Lehman = new ethers.Contract(CONTRACTS.LEHMAN, ABI.LEHMAN, provider)
    const newBranchFilter = Lehman.filters.BranchOpened()
    const _branches = await Lehman.queryFilter(newBranchFilter, 0, 'latest')
    const branches = _branches.map((branch) => branch.args)

    const branchAddressesPromises = branches.map((branch) =>
      Lehman.branches(branch.areaCode, branch.underlying, branch.id),
    )
    const branchAddresses = await Promise.all(branchAddressesPromises)

    const BRANCHES = branchAddresses.map(
      (branchAddress) =>
        new ethers.Contract(branchAddress, ABI.BRANCH, provider),
    )

    const branchMaxDebtPromises = branchAddresses.map((address, index) =>
      BRANCHES[index].maxLoanDebt(0),
    )
    const branchBaseDataCachePromises = branchAddresses.map((address, index) =>
      BRANCHES[index].getBranchData(),
    )

    const [branchMaxDebt, branchDataCache] = await Promise.all([
      Promise.all(branchMaxDebtPromises),
      Promise.all(branchBaseDataCachePromises),
    ])

    const allBranches = branches.map((branch, index) => {
      const branchAddress = branchAddresses[index]
      return {
        BRANCH: new ethers.Contract(branchAddress, ABI.BRANCH, provider),
        address: branchAddress,
        nft: branch.areaCode,
        currency: branch.underlying,
        index: branch.id.toString(),
        maxLoanSize: ethers.utils.formatEther(branch.maxLoanSize).toString(),
        maxLoanDebt: ethers.utils.formatEther(branchMaxDebt[index]).toString(),
        currentRate: branchDataCache[index].rate.toString(),
      }
    })

    resolve(
      allBranches.filter(
        (branch) => DEPRECATED_BRANCHES.indexOf(branch.address) === -1,
      ),
    )
  })
}

const getDebtFromScaledDebt = (scaledDebt, interestAccumulator) => {
  let totalDebt = scaledDebt.mul(interestAccumulator)
  totalDebt = totalDebt.add(ethers.BigNumber.from(PRECISION_1E27).sub(1))
  totalDebt = totalDebt.div(ethers.BigNumber.from(PRECISION_1E27))
  return totalDebt
}

const getBranchSupply = (branchAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const BRANCH = new ethers.Contract(branchAddress, ABI.BRANCH, provider)
      const dataCache = await BRANCH.getBranchData()
      let totalDebt = ethers.utils
        .formatEther(
          getDebtFromScaledDebt(
            dataCache.totalScaledDebt,
            dataCache.interestAccumulator,
          ).toString(),
        )
        .toString()
      let totalSupply = ethers.utils
        .formatEther(dataCache.totalSupply)
        .toString()
      let totalUnderlying = ethers.utils
        .formatEther(dataCache.totalUnderlying)
        .toString()
      resolve({ totalDebt, totalSupply, totalUnderlying })
    } catch (error) {
      reject(error)
    }
  })
}

const getBranchData = (branchAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const BRANCH = new ethers.Contract(branchAddress, ABI.BRANCH, provider)

      const [
        nft,
        currency,
        lpToken,
        maxLoan,
        maxDebt,
        dataCache,
        loanCount,
      ] = await Promise.all([
        BRANCH.areaCode(),
        BRANCH.underlyingToken(),
        BRANCH.accountingToken(),
        BRANCH.maxLoanSize(0),
        BRANCH.maxLoanDebt(0),
        BRANCH.getBranchData(),
        BRANCH.loanCount(),
      ])

      const loanNfts = [...Array(Number(loanCount)).keys()]

      console.log('loans', loanNfts)
      console.log('dataCache', dataCache)

      let totalDebt = ethers.utils
        .formatEther(
          getDebtFromScaledDebt(
            dataCache.totalScaledDebt,
            dataCache.interestAccumulator,
          ).toString(),
        )
        .toString()

      let totalSupply = ethers.utils
        .formatEther(dataCache.totalSupply)
        .toString()
      let totalUnderlying = ethers.utils
        .formatEther(dataCache.totalUnderlying)
        .toString()

      const [
        loanPromises,
        nftPriceFloor,
        nftxPriceFloor,
        sudoPriceFloor,
      ] = await Promise.all([
        Promise.all(loanNfts.map((loanId) => BRANCH.getLoan(loanId))),
        axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v2/${'BQoJ4oYh8QDYABD3ISP8g2r8MIO5Ubdz'}/getFloorPrice?contractAddress=${nft}`,
        ),
        getNFTXPriceFloor(nft),
        getSudoPriceFloor(nft),
      ])

      const _allLoans = loanPromises.map((loan) => {
        return {
          debt: loan.debt,
          house: loan.house,
          owner: loan.owner,
          scaledDebt: loan.scaledDebt,
          ts: loan.ts,
        }
      })

      console.log('allLoans', _allLoans)

      let loanNftData = await Promise.all(
        _allLoans.map((loan) => {
          if (loan.owner !== '0x0000000000000000000000000000000000000000') {
            return axios.get(
              `https://eth-mainnet.g.alchemy.com/nft/v2/${'BQoJ4oYh8QDYABD3ISP8g2r8MIO5Ubdz'}/getNFTMetadata?contractAddress=${nft}&tokenId=${loan.house.toString()}&tokenType=ERC721`,
            )
          } else {
            return new Promise((resolve, reject) => {
              resolve(true)
            })
          }
        }),
      )

      let allLoans = []
      _allLoans.map((loan, loanIndex) => {
        if (loan) {
          const timeToLiquidation = (maxDebt, currentDebt, apr) => {
            if (currentDebt >= maxDebt) {
              return secondsToDhms(0)
            } else {
              const timeInSeconds =
                Math.log(maxDebt / currentDebt) / Math.log(1 + apr)
              return secondsToDhms(timeInSeconds)
            }
          }
          if (loan.owner !== '0x0000000000000000000000000000000000000000') {
            allLoans.push({
              owner: loan.owner,
              branchAddress: branchAddress,
              nftAddress: nft,
              nftId: loan.house.toString(),
              nftMedia: loanNftData[loanIndex].data.media,
              nftMetadata: loanNftData[loanIndex].data.metadata,
              nftContract: loanNftData[loanIndex].data.contractMetadata,
              loanId: loanNfts[loanIndex],
              debt: {
                current: ethers.utils.formatEther(loan.debt).toString(),
                initial: ethers.utils.formatEther(maxLoan).toString(),
                max: ethers.utils.formatEther(maxDebt).toString(),
              },
              canLiquidate: ethers.utils
                .parseEther(maxDebt.toString())
                .lte(ethers.utils.parseEther(loan.debt.toString())),
              timeToLiquidation: timeToLiquidation(
                Number(ethers.utils.formatEther(maxDebt)),
                Number(ethers.utils.formatEther(loan.debt).toString()),
                Number(
                  ethers.BigNumber.from(dataCache.rate.toString()).toString(),
                ) / 1e27,
              ),
              timestamp: loan.ts.toString(),
            })
          }
        }
      })

      console.log(nftxPriceFloor)

      const apr =
        (Number(
          ethers.utils.formatEther(dataCache.accumulatedToBranch.toString()),
        ) /
          0.5 /
          7) *
        365

      const branch = {
        BRANCH: BRANCH,
        address: branchAddress,
        nft: nft,
        nftPriceFloors: {
          ...nftPriceFloor.data,
          nftx_random: nftxPriceFloor?.random,
          nftx_target: nftxPriceFloor?.target,
          sudoswap: sudoPriceFloor,
        },
        currency: currency,
        lpToken: lpToken,
        totalDebt: totalDebt,
        totalSupply: totalSupply,
        totalUnderlying: totalUnderlying,
        maxLoanSize: ethers.utils.formatEther(maxLoan).toString(),
        maxLoanDebt: ethers.utils.formatEther(maxDebt).toString(),
        currentRate: dataCache.rate.toString(),
        accumulatedToBranch: ethers.utils
          .formatEther(dataCache.accumulatedToBranch.toString())
          .toString(),
        apr: apr,
        loans: allLoans,
      }

      resolve(branch)
    } catch (error) {
      reject(error)
    }
  })
}

const getUserLoans = (user, branches) => {
  return new Promise(async (resolve, reject) => {
    const targetWallet = user
    const _branches = branches

    const userNftsPromises = _branches.map((branch) => {
      const { BRANCH } = branch
      const filter = BRANCH.filters.Transfer(null, targetWallet)
      return BRANCH.queryFilter(filter, 0, 'latest')
    })
    const _userNfts = await Promise.all(userNftsPromises)
    const userNfts = _userNfts.map((nft) =>
      nft.map((n) => n.args.id.toString()),
    )

    const userLoanPromises = userNfts.map((nfts, index) => {
      if (nfts) {
        return Promise.all(
          nfts.map((nft) => _branches[index].BRANCH.getLoan(nft)),
        )
      } else {
        return new Promise((resolve, reject) => {
          resolve([])
        })
      }
    })

    const _userLoans = await Promise.all(userLoanPromises)
    let userLoans = []
    _userLoans.map((loans, index) => {
      if (loans) {
        const timeToLiquidation = (maxDebt, currentDebt, apr) => {
          if (currentDebt >= maxDebt) {
            return secondsToDhms(0)
          } else {
            const timeInSeconds =
              Math.log(maxDebt / currentDebt) / Math.log(1 + apr)
            return secondsToDhms(timeInSeconds)
          }
        }
        return loans.map((loan, loanIndex) => {
          if (loan.owner.toLowerCase() === targetWallet.toLowerCase()) {
            userLoans.push({
              branchAddress: _branches[index].address,
              nftAddress: _branches[index].nft,
              nftId: loan.house.toString(),
              loanId: userNfts[index][loanIndex],
              debt: {
                current: ethers.utils.formatEther(loan.debt).toString(),
                initial: COLLECTIONS[
                  _branches[index].nft.toLowerCase()
                ].maxLoan.toString(),
                max: _branches[index].maxLoanDebt,
              },
              canLiquidate: ethers.utils
                .parseEther(_branches[index].maxLoanDebt)
                .lte(ethers.utils.parseEther(loan.debt.toString())),
              timeToLiquidation: timeToLiquidation(
                Number(_branches[index].maxLoanDebt),
                Number(ethers.utils.formatEther(loan.debt).toString()),
                Number(
                  ethers.BigNumber.from(
                    _branches[index].currentRate,
                  ).toString(),
                ) / 1e27,
              ),
              timestamp: loan.ts.toString(),
            })
          }
        })
      }
    })

    let uniqueNfts = []
    userLoans.forEach((loan) => {
      if (uniqueNfts.indexOf(loan.nftAddress) === -1)
        uniqueNfts.push(loan.nftAddress)
    })

    let _nftPriceFloors = Promise.all(
      uniqueNfts.map((nftAddress) =>
        axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v2/${'BQoJ4oYh8QDYABD3ISP8g2r8MIO5Ubdz'}/getFloorPrice?contractAddress=${nftAddress}`,
        ),
      ),
    )

    let _nftData = Promise.all(
      userLoans.map((loan) =>
        axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v2/${'BQoJ4oYh8QDYABD3ISP8g2r8MIO5Ubdz'}/getNFTMetadata?contractAddress=${
            loan.nftAddress
          }&tokenId=${loan.nftId}&tokenType=ERC721&refreshCache=true`,
        ),
      ),
    )

    const [nftPriceFloors, nftData] = await Promise.all([
      _nftPriceFloors,
      _nftData,
    ])

    let hydratedUserLoans = userLoans.map((loan, index) => {
      let parsedPriceFloors = []

      //console.log(nftPriceFloors)
      if (nftPriceFloors[0].data.looksRare) {
        // MAKE DYNAMIC
        parsedPriceFloors.push({
          marketaplce: 'looksrare',
          floorPrice: nftPriceFloors[0].data.looksRare.floorPrice,
          url: nftPriceFloors[0].data.looksRare.collectionUrl,
        })
      }
      if (nftPriceFloors[0].data.openSea) {
        parsedPriceFloors.push({
          marketaplce: 'opensea',
          floorPrice: nftPriceFloors[0].data.openSea.floorPrice,
          url: nftPriceFloors[0].data.openSea.collectionUrl,
        })
      }
      parsedPriceFloors = parsedPriceFloors.sort(
        (a, b) => a.floorPrice - b.floorPrice,
      )

      return {
        ...loan,
        nftMedia: nftData[index].data.media,
        nftMetadata: nftData[index].data.metadata,
        nftContract: nftData[index].data.contractMetadata,
        nftPriceFloors: parsedPriceFloors,
      }
    })

    //console.log('hydratedUserLoans', hydratedUserLoans)

    resolve(hydratedUserLoans)
  })
}

const getUserWethBalanceAndApprovals = (user, branches) => {
  return new Promise(async (resolve, reject) => {
    const targetWallet = user

    const WETH = new ethers.Contract(CONTRACTS.WETH, ABI.WETH, provider)
    const wethBalancePromise = WETH.balanceOf(targetWallet)
    const wethApprovalsPromise = branches.map((pool) => {
      return WETH.allowance(targetWallet, pool.address)
    })

    const [_wethBalance, _wethApprovals] = await Promise.all([
      wethBalancePromise,
      Promise.all(wethApprovalsPromise),
    ])

    resolve({ _wethBalance, _wethApprovals })
  })
}

const getUserLPTokenBalanceAndApprovals = (user, lpToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const LP_TOKEN = new ethers.Contract(lpToken, ABI.ERC20, provider)
      const [balance, approval] = await Promise.all([
        LP_TOKEN.balanceOf(user),
        LP_TOKEN.allowance(user, CONTRACTS.PRIVATEBANKING),
      ])

      resolve({ balance, approval })
    } catch (error) {
      reject(error)
    }
  })
}

export {
  getBranches,
  getBranchSupply,
  getUserLoans,
  getUserLPTokenBalanceAndApprovals,
  getUserWethBalanceAndApprovals,
  getBranchData,
}
