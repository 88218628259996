module.exports = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_manager',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_accountingImplementation',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_branchImplementation',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_rolodex',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_branchFee',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'CreateFail',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'contract Authority',
        name: 'newAuthority',
        type: 'address',
      },
    ],
    name: 'AuthorityUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'underlying',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'areaCode',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maxLoanSize',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
    ],
    name: 'BranchOpened',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnerUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'receiver',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'PayDay',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ACCOUNTING_ERC20_IMPLEMENTATION',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'BRANCH_FEE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'BRANCH_IMPLEMENTATION',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ROLODEX',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'authority',
    outputs: [
      {
        internalType: 'contract Authority',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'branches',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_areaCode',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_underlying',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_index',
        type: 'uint256',
      },
    ],
    name: 'getBranchData',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'interestAccumulator',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'rate',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'ts',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalScaledDebt',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'totalUnderlying',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'accumulatedToBranch',
            type: 'uint256',
          },
        ],
        internalType: 'struct DataStructures.BranchDataCache',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_areaCode',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_underlying',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_index',
        type: 'uint256',
      },
    ],
    name: 'getBranchDataStorage',
    outputs: [
      {
        components: [
          {
            internalType: 'uint128',
            name: 'interestAccumulator',
            type: 'uint128',
          },
          {
            internalType: 'uint96',
            name: 'rate',
            type: 'uint96',
          },
          {
            internalType: 'uint32',
            name: 'ts',
            type: 'uint32',
          },
          {
            internalType: 'uint128',
            name: 'totalScaledDebt',
            type: 'uint128',
          },
          {
            internalType: 'uint128',
            name: 'totalSupply',
            type: 'uint128',
          },
          {
            internalType: 'uint128',
            name: 'totalUnderlying',
            type: 'uint128',
          },
          {
            internalType: 'uint128',
            name: 'accumulatedToBranch',
            type: 'uint128',
          },
        ],
        internalType: 'struct DataStructures.BranchData',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_areaCode',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_underlying',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_index',
        type: 'uint256',
      },
    ],
    name: 'getBranchSettings',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'baseRate',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'slope1',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'slope2',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'kink',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'branchFee',
            type: 'uint256',
          },
        ],
        internalType: 'struct DataStructures.BranchSettings',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_areaCode',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_underlying',
        type: 'address',
      },
    ],
    name: 'getNumberOfBranches',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_underlying',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_areaCode',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_maxLoanSize',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_maxLoanDebt',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_baseRate',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_slope1',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_slope2',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_kink',
        type: 'uint256',
      },
    ],
    name: 'openBranch',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_receiver',
        type: 'address',
      },
    ],
    name: 'payDay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract Authority',
        name: 'newAuthority',
        type: 'address',
      },
    ],
    name: 'setAuthority',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'setOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
