import { useState, useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { setUserProperties, logEvent } from 'firebase/analytics'
import { useAccountModal, useConnectModal } from '@rainbow-me/rainbowkit'
import axios from 'axios'
import { ethers, BigNumber } from 'ethers'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { CONTRACTS, ABI } from '../config.js'
import NFTCard_V2 from '../components/NFTCard_V2'
import { hydrateSudoPoolsAndNormalize } from '../helpers/utils'
import PudgyPenguinsIcon from '../assets/images/PudgyPenguins_Icon.png'
import TubbyCatsIcon from '../assets/images/TubbyCats_Icon.png'

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
const pudgypenguins = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'
const lilpudgies = '0x524cab2ec69124574082676e6f654a18df49a048'

const BailoutPools = {
  [tubbycats]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.08',
    name: 'Tubbycats',
  },
  [miladys]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.30',
    name: 'Milady',
  },
  [otherdeeds]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '1.50',
    name: 'Otherdeeds',
  },
  [pudgypenguins]: {
    address: '0x73D843995eEF3A2A8296d756cef488eaf81Bde62',
    maxLoan: '2.50',
    name: 'Pudgies',
  },
  [lilpudgies]: {
    address: '0x76A8402C0754431ae0B1AEdA8FEdB880fB57507a',
    maxLoan: '0.15',
    name: 'Lil Pudgies',
  },
}

export default function Collection_V2({ analytics }) {
  let { address } = useParams()
  address = address.toLowerCase()
  if (address === 'pudgypenguins' || address === 'pudgy-penguins') {
    address = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'
  } else if (address === 'tubbycats' || address === 'tubby-cats') {
    address = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
  } else if (
    address === 'miladies' ||
    address === 'miladymaker' ||
    address === 'milady-maker' ||
    address === 'milady'
  ) {
    address = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
  } else if (
    address === 'otherdeeds' ||
    address === 'otherdeeds-for-otherside'
  ) {
    address = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
  } else if (address === 'lilpudgies' || address === 'lil-pudgies') {
    address = lilpudgies
  }

  let navigate = useNavigate()

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  // * > WEB3 INTEGRATIONS
  const { openConnectModal } = useConnectModal()
  const { openAccountModal } = useAccountModal()
  const { address: wallet, isConnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if (isReconnected) {
        setUserProperties(analytics, { wallet: 'wallet:' + address })
      } else {
        logEvent(analytics, 'login', {
          method: connector.name,
          wallet: 'wallet:' + address,
        })
        setUserProperties(analytics, { wallet: 'wallet:' + address })
      }
    },
  })
  const { data: walletBalance, isFetched: isWalletBalanceFetched } = useBalance(
    {
      addressOrName: wallet,
    },
  )
  const signer = useSigner()

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  // * > COLLECTION METATADATA
  const [isLoadingCollection, setIsLoadingCollection] = useState(true)
  const [collectionMetadata, setCollectionMetadata] = useState({})
  const [collectionFloorPrice, setCollectionFloorPrice] = useState('')

  // * > MARKETPLACE LISTINGS
  const [isLoadingListings, setIsLoadingListings] = useState(true)
  const [allListings, setAllListings] = useState([])
  const [visibleListings, setVisibleListings] = useState(12)

  // * > TRANSACTION STATE
  const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false)
  const [isTxModalOpen, setIsTxModalOpen] = useState(false)
  const [txData, setTxData] = useState({})
  const [isSendingTx, setIsSendingTx] = useState(false)
  const [isTxSent, setIsTxSent] = useState(false)
  const [isTxMined, setIsTxMined] = useState(false)
  const [isTxSuccessful, setIsTxSuccessful] = useState(false)

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //

  useEffect(() => {
    if (!address) {
      navigate('/')
    } else {
      // * Gets collection metadata /
      axios
        .get(
          `https://us-central1-bailout-cee17.cloudfunctions.net/getCollectionMetadata?nft=${address}&includeSalesData=true`,
        )
        .then((response) => {
          setCollectionMetadata(response.data)
          setIsLoadingCollection(false)
        })
        .catch((error) => navigate('/'))

      // * Gets collection listings /

      const listingCacheKey = `listings:${address}`
      let CachedListings = localStorage.getItem(listingCacheKey)
      if (CachedListings) {
        const { timestamp, listings } = JSON.parse(CachedListings)
        setCollectionFloorPrice(
          Number(ethers.utils.formatEther(listings[0].price).toString()),
        )
        setAllListings(listings)
        setIsLoadingListings(false)
      }

      const getSudoListings = () => {
        return new Promise((resolve, reject) => {
          axios
            .get(
              `https://us-central1-bailout-cee17.cloudfunctions.net/getSudoPricesForNft?nft=${address}`,
            )
            .then((response) => {
              const sudo = response.data
              hydrateSudoPoolsAndNormalize(sudo, address)
                .then((hydratedSudoPools) => {
                  resolve(hydratedSudoPools)
                })
                .catch((error) => {
                  reject(error)
                })
            })
            .catch((error) => {
              reject(error)
            })
        })
      }

      Promise.all([
        axios.get(
          `https://us-central1-bailout-cee17.cloudfunctions.net/getAskListingsForNft?nft=${address}`,
        ),
        getSudoListings(),
      ]).then((responses) => {
        const listings = responses[0].data
        const sudoListings = responses[1]
        let allListings = listings.concat(sudoListings).sort((a, b) => {
          let _a = Number(ethers.utils.formatEther(a.price))
          let _b = Number(ethers.utils.formatEther(b.price))
          return _a - _b
        })

        let ids = {}
        let allFilteredListings = []
        allListings.forEach((listing) => {
          if (!ids[listing.tokenSetId]) {
            ids[listing.tokenSetId] = true
            allFilteredListings.push(listing)
          }
        })

        console.log('allFilteredListings', allFilteredListings)
        setCollectionFloorPrice(
          Number(
            ethers.utils.formatEther(allFilteredListings[0].price).toString(),
          ),
        )
        setAllListings(allFilteredListings)
        setIsLoadingListings(false)

        localStorage.setItem(
          listingCacheKey,
          JSON.stringify({
            timestamp: Date.now(),
            listings: allFilteredListings,
          }),
        )
      })
    }
  }, [])
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //

  console.log(allListings)

  const handleTxModalOpen = (isOpen, nft, marketplace) => {
    if (wallet) {
      setIsTxModalOpen(isOpen)
      if (nft) {
        setTxData({ nft })
      }
    }
  }
  const sendTx = (order) => {
    return new Promise(async (resolve, reject) => {
      if (!wallet) {
        openConnectModal()
      } else {
        console.log('order', order)

        const bailoutPool = BailoutPools[address].address
        const maxBailoutLoan = BigNumber.from(
          ethers.utils.parseEther(BailoutPools[address].maxLoan),
        )
        const maxUserPayment = BigNumber.from(order.price).sub(maxBailoutLoan)

        const CheckoutV2 = new ethers.Contract(
          CONTRACTS.CHECKOUT_V2,
          ABI.CHECKOUT_V2,
          signer.data,
        )

        if (maxUserPayment.lt(walletBalance.value)) {
          setHasInsufficientFunds(false)
          setTxData({ marketplace: order.kind, nft: order })
          setIsSendingTx(true)
          setIsTxSent(false)
          setIsTxMined(false)

          try {
            let txResponse
            if (order.kind === 'sudoswap') {
              txResponse = await CheckoutV2.bailoutSudoswap(
                address,
                order.sourceAddress,
                bailoutPool,
                maxUserPayment,
                order.nftId,
                {
                  value: maxUserPayment,
                  gasLimit: 400000,
                },
              )
            } else if (order.kind === 'looks-rare') {
              const response = await axios.get(
                `https://us-central1-bailout-cee17.cloudfunctions.net/getOrderData?order=${order.id}`,
              )
              const { isValid, marketplace, rawData } = response.data
              console.log('rawTxData', rawData)

              txResponse = await CheckoutV2.bailoutLooksrare(
                bailoutPool,
                maxUserPayment,
                rawData,
                {
                  value: maxUserPayment,
                  gasLimit: 500000,
                },
              )
            } else if (order.kind === 'seaport') {
              const response = await axios.get(
                `https://us-central1-bailout-cee17.cloudfunctions.net/getOrderData?order=${order.id}`,
              )
              const { isValid, marketplace, rawData } = response.data
              console.log('rawTxData', rawData)

              const FormatSeaportOrder = (order) => {
                const consideration = order.consideration
                const additionalRecipients = consideration.slice(1)
                return {
                  considerationToken: consideration[0].token,
                  considerationIdentifier: BigNumber.from(
                    consideration[0].identifierOrCriteria,
                  ),
                  considerationAmount: consideration[0].endAmount,
                  offerer: order.offerer,
                  zone: order.zone,
                  offerToken: order.offer[0].token,
                  offerIdentifier: BigNumber.from(
                    order.offer[0].identifierOrCriteria,
                  ),
                  offerAmount: BigNumber.from(order.offer[0].endAmount),
                  basicOrderType: BigNumber.from(order.orderType),
                  startTime: order.startTime,
                  endTime: order.endTime,
                  zoneHash: order.zoneHash,
                  salt: order.salt,
                  offererConduitKey: order.conduitKey,
                  fulfillerConduitKey: ethers.constants.HashZero,
                  totalOriginalAdditionalRecipients: BigNumber.from(
                    additionalRecipients.length,
                  ),
                  additionalRecipients: [
                    ...order.consideration
                      .slice(1)
                      .map(({ endAmount, recipient }) => ({
                        amount: BigNumber.from(endAmount),
                        recipient,
                      })),
                    ...[],
                  ],
                  signature: order.signature,
                }
              }
              const seaportOrder = FormatSeaportOrder(rawData)
              txResponse = await CheckoutV2.bailoutSeaport(
                bailoutPool,
                maxUserPayment,
                seaportOrder,
                {
                  value: maxUserPayment,
                  gasLimit: 450000,
                },
              )
            } else {
              setTxData({})
              setIsSendingTx(false)
            }

            setIsTxSent(true)
            const txReceipt = await txResponse.wait()
            setIsTxMined(true)
            setIsTxSuccessful(txReceipt.status ? true : false)

            // * Remove filled listing from allListings array
            let updatedListings = []
            allListings.forEach((listing) => {
              if (listing.id !== order.id) updatedListings.push(listing)
            })
            setAllListings(updatedListings)
          } catch (error) {
            setIsTxModalOpen(false)
            setIsSendingTx(false)
            //setTxData({})
          }
        } else {
          setHasInsufficientFunds(true)
          setTxData({ marketplace: order.kind, nft: order })
        }
      }
    })
  }
  const getModalContent = () => {
    let title
    let subtitle
    let showButton = true
    let buttonText = 'View NFT'
    let buttonStyle = 'default'
    if (isSendingTx) {
      if (isTxSent) {
        if (isTxMined) {
          if (isTxSuccessful) {
            title = 'Transaction Successful'
            subtitle =
              'Your NFT has been purchased and is now in your wallet. Remember to pay the second installment within the next 7 days.'
          } else {
            title = 'Transaction Failed'
            subtitle = 'Please check etherscan to see what happened.'
            buttonText = 'Close Window'
          }
        } else {
          title = 'Mining Transaction'
          subtitle = 'Please wait while the transaction is pending.'
          showButton = false
        }
      } else {
        title = 'Sending Transaction'
        subtitle = 'Please approve the transaction in your wallet.'
        showButton = false
      }
    } else {
      if (hasInsufficientFunds) {
        title = 'Insufficient Funds'
        subtitle = 'You do not have enough ETH for this purchase'
        buttonText = 'Close Window'
        buttonStyle = 'outline'
      } else {
        title = 'Sending Transaction'
        subtitle = 'Please approve the transaction in your wallet.'
        showButton = false
      }
    }
    return { title, subtitle, showButton, buttonText, buttonStyle }
  }

  const getNftImage = (_url) => {
    let url = _url

    if (url.indexOf('/ipfs/') !== -1) {
      url = 'https://bailout.mypinata.cloud/ipfs/' + url.split('ipfs/')[1]
    }

    return url
  }

  const CollateralCard = (props) => {
    const { txData } = props
    if (txData !== {}) {
      console.log(txData)
      console.log('split', txData.nft.tokenSetId.split(':'))

      const maxBailoutLoan = BigNumber.from(
        ethers.utils.parseEther(BailoutPools[address].maxLoan),
      )
      const maxUserPayment = BigNumber.from(txData.nft.price).sub(
        maxBailoutLoan,
      )
      return (
        <div className="w-full flex flex-col pb-3 mb-3 ">
          <div className="w-full flex flex-row items-center justify-between border border-gray-300 rounded-lg shadow">
            <div className="whitespace-nowrap py-2 px-1 text-sm border-r border-gray-300 min-w-[145px] max-w-[145px]">
              <div className="flex items-center">
                <div className="h-10 w-10 flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={getNftImage(txData.nft.nftImage)}
                    alt="loan nft"
                  />
                </div>
                <div className="ml-2 ">
                  <div className="text-gray-900">
                    #{txData.nft.tokenSetId.split(':')[2]}
                  </div>
                  <div className="text-ellipsis text-xs font-mono overflow-hidden text-gray-500">
                    {BailoutPools[txData.nft.tokenSetId.split(':')[1]].name}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full whitespace-nowrap px-1 py-2  text-sm text-gray-500  ">
              <div className="text-gray-600"> Duration </div>
              <div className="font-mono text-gray-500">7 Days</div>
            </div>
            <div className="flex flex-col whitespace-nowrap px-3 py-2  text-sm text-gray-500 border-l border-gray-300 bg-gray-50 rounded-r-lg shadow-inner">
              <div className="text-gray-600"> Amount </div>
              <div className="font-mono text-gray-700">
                {Number(
                  ethers.utils.formatEther(maxUserPayment).toString(),
                ).toFixed(3) + ' ETH'}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //

  return (
    <>
      <div className="max-h-full h-screen bg-gray-50">
        <Helmet>
          <title>Bailout - Collection: {address}</title>
        </Helmet>
        <Transition.Root show={isTxModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[100] overflow-hidden"
            onClose={() => {
              if (!isSendingTx || isTxMined) {
                setIsTxModalOpen(false)
              } else {
                setIsTxModalOpen(true)
              }
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-hidden sm:overflow-y-auto">
              <div className="flex min-h-full items-end justify-center text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="sm:opacity-0 translate-y-32 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="sm:opacity-0 translate-y-32 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden sm:rounded-lg shadow-xl transition-all w-full sm:max-w-sm ">
                    <div className="hidden sm:flex w-full h-8 bg-gradient-to-r from-green-600 via-green-500 to-green-500 rounded-t-lg">
                      <div
                        className={`${
                          isTxSent
                            ? isTxMined
                              ? ''
                              : 'swipe-animation bg-white/5'
                            : ''
                        } h-full w-[5%]  rounded-t-lg`}
                      />
                    </div>
                    <div className="rounded-t-3xl sm:rounded-none bg-white px-4 pb-8 pt-6 sm:pb-4 text-left sm:p-6 sm:pt-4">
                      <div>
                        <div className="text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl font-proximaNova font-bold leading-6 text-gray-900"
                          >
                            {getModalContent().title}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className=" text-gray-500 font-proximaNova text-md">
                              {getModalContent().subtitle}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <CollateralCard txData={txData} />

                        {getModalContent().showButton && (
                          <div
                            type="button"
                            className={`${
                              getModalContent().buttonStyle === 'default'
                                ? 'bg-gradient-to-tr from-green-400 via-green-500 to-green-400 font-bold text-white shadow'
                                : 'border border-green-500 text-green-600 font-inter font-semibold'
                            } inline-flex w-full justify-center rounded-md  cursor-pointer hover:opacity-90 px-4 py-2 text-base focus:outline-none focus:ring-0 focus:ring-offset-0 sm:text-sm`}
                            onClick={() => {
                              if (isTxSuccessful) {
                                navigate(`/wallet`)
                              } else {
                                setIsTxModalOpen(false)
                              }
                            }}
                            //onClick={() => setIsTxModalOpen(false)}
                          >
                            {getModalContent().buttonText}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="hidden sm:flex w-full h-8 bg-gradient-to-r from-green-600 via-green-500 to-green-500 rounded-b-lg">
                      <div
                        className={`${
                          isTxSent
                            ? isTxMined
                              ? ''
                              : 'swipe-animation-reverse bg-white/5'
                            : ''
                        } h-full w-[5%] rounded-b-lg `}
                      />
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <main className="max-h-full h-full sm:pt-6">
          {/* Page header */}

          <div
            className="max-h-full h-full overflow-hidden mx-auto sm:pb-8 grid max-w-3xl sm:auto-rows-auto	 grid-cols-1 sm:gap-x-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3"
            style={{ gridTemplateRows: 'auto 1fr' }}
          >
            <div className="w-full col-span-1 lg:col-span-3 lg:row-start-1 sm:pb-6">
              <div className="w-full flex flex-row items-center justify-between bg-gradient-to-r from-green-600 via-green-500 to-green-400 ">
                <div
                  className="w-28 shrink font-bailoutRegular pl-4 pb-1 pt-2 text-white cursor-pointer hover:text-white/90"
                  onClick={() => navigate('/')}
                >
                  BAILOUT
                </div>
                <div
                  onClick={() => navigate('/wallet')}
                  className="shrink text-white font-semibold hover:text-white/90 cursor-pointer flex flex-col h-full justify-center pr-4"
                >
                  Payments
                </div>
              </div>
            </div>
            <section
              aria-labelledby="wallet-widget"
              className="lg:col-span-1 lg:col-start-1 lg:row-start-2"
            >
              <div className="bg-white border border-gray-200 ">
                <div className="relative w-full h-full">
                  <div className="xl:max-w-[418px] w-full bg-white grow-0 mr-16">
                    {/* * * * * * WALLET * * * * * */}
                    <div
                      className={`${
                        !isConnected ? 'hidden' : ''
                      } border-b border-gray-200 py-4 px-6 justify-between w-full flex flex-row  items-center`}
                    >
                      <div className="border-green-600 font-inter font-semibold text-green-600 items-center flex flex-row">
                        <div
                          onClick={() => openAccountModal()}
                          className="flex flex-col leading-tight font-mono group w-full "
                        >
                          <div className="w-full inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                            {Number(
                              ethers.utils
                                .formatEther(
                                  isWalletBalanceFetched
                                    ? walletBalance.value
                                    : '0',
                                )
                                .toString(),
                            ).toFixed(3) + ' ETH'}
                          </div>
                          <div className="inline-flex leading-tight text-gray-500 text-sm font-medium ">{`${
                            wallet ? wallet.slice(0, 17) : ''
                          }...`}</div>
                        </div>
                      </div>
                      <div
                        onClick={() => openAccountModal()}
                        className="inline-flex h-full  items-center border-l border-gray-300 pl-3 cursor-pointer text-gray-500 hover:opacity-80 font-medium text-sm"
                      >
                        Wallet
                      </div>
                    </div>
                    {/* * * * * * * * * * * * * */}

                    <div className="w-full sm:hidden">
                      <Disclosure className="flex w-full h-full sm:hidden">
                        {({ open }) => (
                          /* Use the `open` state to conditionally change the direction of an icon. */
                          <>
                            <Disclosure.Button className="text-lg font-medium text-gray-900 flex flex-row items-center px-2 pt-2 pb-2 w-full">
                              <ChevronRightIcon
                                className={
                                  open
                                    ? 'rotate-90 transform h-6 w-6 inline-flex'
                                    : 'h-6 w-6 inline-flex'
                                }
                              />
                              How it works
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <div className="flex flex-col px-4 pb-5 sm:px-6 sm:border-t border-gray-200">
                                <div key="1" className="relative pt-2">
                                  <dt>
                                    <div className="absolute flex h-10 w-10 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                                        />
                                      </svg>
                                    </div>
                                    <p className="ml-14 text-md font-medium text-gray-900 leading-none">
                                      1. Make a downpayment
                                    </p>
                                  </dt>
                                  <dd className="ml-14 text-sm text-gray-500 tracking-tight leading-tight mt-1">
                                    Pay as little as 20% of the price of the nft
                                    upfront. You can sell your nft at any time
                                    after this payment.
                                  </dd>
                                </div>
                                <div key="2" className="relative pt-4">
                                  <dt>
                                    <div className="absolute flex h-10 w-10 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                                        />
                                      </svg>
                                    </div>
                                    <p className="ml-14 text-md font-medium text-gray-900 leading-none">
                                      2. Pay the rest in 7 days
                                    </p>
                                  </dt>
                                  <dd className="ml-14 text-sm text-gray-500 tracking-tight leading-tight mt-1">
                                    Pay the remainder within 7 days along with a
                                    capped max fee of about $2-$5 for Tubby Cats
                                    and $50-$60 for Pudgy Penguins. No
                                    liquidation risk within this timeframe.
                                  </dd>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="hidden sm:flex flex-col w-full px-4 pt-3 pb-5 sm:px-6">
                      <h2
                        id="lp-widget-deposit"
                        className="text-lg font-medium text-gray-900"
                      >
                        How it works
                      </h2>
                      <p className="text-sm text-gray-500">
                        Bailout Pay works by taking a users initial downpayment
                        and then collateralizing the purchased nft in a Bailout
                        lending pool.
                      </p>
                    </div>

                    <div className="hidden sm:flex flex-col px-4 pb-5 sm:px-6 sm:border-t border-gray-200">
                      <div key="1" className="relative pt-4">
                        <dt>
                          <div className="absolute flex h-10 w-10 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                              />
                            </svg>
                          </div>
                          <p className="ml-14 text-md font-medium text-gray-900 leading-none">
                            1. Make a downpayment
                          </p>
                        </dt>
                        <dd className="ml-14 text-sm text-gray-500 tracking-tight leading-tight mt-1">
                          Pay as little as 20% of the price of the nft upfront.
                          You can sell your nft at any time after this payment.
                        </dd>
                      </div>
                      <div key="2" className="relative pt-4">
                        <dt>
                          <div className="absolute flex h-10 w-10 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                              />
                            </svg>
                          </div>
                          <p className="ml-14 text-md font-medium text-gray-900 leading-none">
                            2. Pay the rest in 7 days
                          </p>
                        </dt>
                        <dd className="ml-14 text-sm text-gray-500 tracking-tight leading-tight mt-1">
                          Pay the remainder within 7 days along with a capped
                          max fee of about $2-$5 for Tubby Cats and $50-$60 for
                          Pudgy Penguins. No liquidation risk within this
                          timeframe.
                        </dd>
                      </div>
                    </div>
                    {/* Collection Card */}
                    <div className="flex flex-col border-t border-gray-200">
                      <div
                        className={` flex flex-row items-center bg-white transition-all duration-200 w-full `}
                      >
                        <img
                          className={`${
                            Boolean(
                              isLoadingCollection &&
                                address !== pudgypenguins &&
                                address !== tubbycats,
                            )
                              ? 'hidden'
                              : ''
                          } h-16 w-16`}
                          src={
                            address === pudgypenguins
                              ? PudgyPenguinsIcon
                              : address === tubbycats
                              ? TubbyCatsIcon
                              : collectionMetadata?.image
                          }
                        />
                        <div
                          className={`${
                            Boolean(
                              isLoadingCollection &&
                                address !== pudgypenguins &&
                                address !== tubbycats,
                            )
                              ? ''
                              : 'hidden'
                          } h-16 w-16 bg-gray-200 animate-pulse`}
                        />
                        <div className="flex flex-col w-full px-4">
                          <h3 className="text-lg font-medium text-gray-900">
                            {address === pudgypenguins
                              ? 'Pudgy Penguins'
                              : address === tubbycats
                              ? 'Tubby Cats'
                              : collectionMetadata?.name}
                          </h3>
                          <h5 className="w-full text-sm flex flex-row justify-between items-center text-gray-800 tracking-tight font-inter">
                            <div className="inline-flex flex-row items-center">
                              <div>
                                Floor:{' '}
                                <div className="inline-flex font-semibold">
                                  {Number(collectionFloorPrice).toFixed(3)}
                                </div>
                              </div>
                              <div className="inline-flex pl-">
                                <svg
                                  width="9"
                                  height="12"
                                  viewBox="0 0 6 9"
                                  fill="inherit"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
                                    fill="#777E91"
                                  />
                                  <path
                                    d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
                                    fill="#777E91"
                                  />
                                </svg>
                              </div>
                            </div>

                            <div>
                              Supply:{' '}
                              <div className="inline-flex font-semibold">
                                {Number(collectionMetadata?.tokenCount)}
                              </div>
                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* * */}
                  </div>
                </div>
              </div>
            </section>

            <div className="space-y-6 max-h-full overflow-y-scroll lg:col-span-2 lg:col-start-2 lg:row-start-2">
              {/* Description list*/}
              <div className="w-full max-h-full overflow-y-auto p-4 sm:bg-white border-x border-b sm:border-x sm:border-y border-gray-200">
                <div
                  className={`${
                    isLoadingListings ? '' : 'hidden'
                  } w-full py-8 flex flex-row justify-center`}
                >
                  <svg
                    className={`animate-spin h-12 w-12 text-gray-400`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <section
                  className={`${
                    isLoadingListings ? 'opacity-0' : 'opacity-100'
                  } transition-all duration-500 w-full grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 grid-rows-3`}
                >
                  {allListings.slice(0, visibleListings).map((item, index) => {
                    return (
                      <NFTCard_V2
                        key={item.id}
                        nft={item}
                        nftContract={address}
                        marketplace={''}
                        poolLiquidity={''}
                        isMobile={false}
                        collectionMetadata={collectionMetadata}
                        txModal={{
                          isTxModalOpen,
                          setIsTxModalOpen: handleTxModalOpen,
                          txData,
                          sendTx,
                        }}
                      />
                    )
                  })}
                  <div className="w-full col-span-2 sm:col-span-2 lg:col-span-4 flex flex-row justify-center ">
                    <div
                      onClick={() => {
                        if (visibleListings <= allListings.length) {
                          setVisibleListings(visibleListings + 12)
                        }
                      }}
                      className="text-gray-500 cursor-pointer"
                    >
                      Load more
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
