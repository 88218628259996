import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConnectButton, useAccountModal } from '@rainbow-me/rainbowkit'
import axios from 'axios'
import { ethers } from 'ethers'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { ABI, ALCHEMY_URL, CONTRACTS } from '../config.js'
import BranchTable from '../components/BranchTable'
import { getUserLPTokenBalanceAndApprovals } from '../helpers/bailout'

const provider = new ethers.providers.JsonRpcProvider(ALCHEMY_URL)

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
const pudgypenguins = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'

const BailoutPools = {
  [tubbycats]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.08',
    name: 'Tubbycats',
  },
  [miladys]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.30',
    name: 'Milady',
  },
  [otherdeeds]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '1.50',
    name: 'Otherdeeds',
  },
  [pudgypenguins]: {
    address: '0x73D843995eEF3A2A8296d756cef488eaf81Bde62',
    maxLoan: '2.50',
    name: 'Pudgies',
  },
}

export default function AdminCheckout({ analytics }) {
  let navigate = useNavigate()
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const signer = useSigner()
  const { openAccountModal } = useAccountModal()
  const { address: wallet, isConnected, isDisconnected } = useAccount()
  const {
    data: walletBalance,
    isError,
    isLoading: isLoadingBalance,
    isFetched,
  } = useBalance({
    addressOrName: wallet,
  })
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [isLoadingLpToken, setIsLoadingLpToken] = useState(true)
  const [lpTokenBalance, setLpTokenBalance] = useState(0)
  const [lpTokenApproval, setLpTokenApproval] = useState(0)
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [depositAmount, setDepositAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()

  const [depositError, setDepositError] = useState('')
  const [withdrawError, setWithdrawError] = useState('')

  const [txType, setTxType] = useState('')
  const [isSendingTx, setIsSendingTx] = useState(false)
  const [isMiningTx, setIsMiningTx] = useState(false)
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  const [isLoading, setIsLoading] = useState(true)
  const [owner, setOwner] = useState('')
  const [paused, setPaused] = useState(false)
  const [balance, setBalance] = useState('')

  const [newOwner, setNewOwner] = useState('')

  useEffect(() => {
    const Checkout = new ethers.Contract(
      CONTRACTS.CHECKOUT_V2,
      ABI.CHECKOUT_V2,
      provider,
    )

    Promise.all([
      Checkout.owner(),
      Checkout.paused(),
      provider.getBalance(CONTRACTS.CHECKOUT_V2),
    ]).then((results) => {
      setOwner(results[0])
      setPaused(results[1])
      setBalance(results[2].toString())
      setIsLoading(false)
    })
  }, [])

  const transferOwner = () => {
    const Checkout = new ethers.Contract(
      CONTRACTS.CHECKOUT_V2,
      ABI.CHECKOUT_V2,
      signer.data,
    )

    Checkout.transferOwnership(newOwner)
  }

  return (
    <>
      <div className="min-h-full bg-gray-50">
        <main className="py-10">
          {/* Page header */}
          <div className="mx-auto max-w-3xl lg:max-w-5xl sm:px-6">
            <div className="w-full bg-gradient-to-r from-green-600 via-green-500 to-green-400 ">
              <div
                className="w-28 shrink font-bailoutRegular pl-4 pb-1 pt-2 text-white cursor-pointer hover:text-white/90"
                onClick={() => navigate('/')}
              >
                BAILOUT
              </div>
            </div>
          </div>

          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-5xl lg:grid-flow-col-dense lg:grid-cols-3">
            <section
              aria-labelledby="lp-widget"
              className="lg:col-span-1 lg:col-start-1"
            >
              {/*
                <div className="mb-4 px-4 py-4 w-full bg-white border border-gray-200">
                  <img src={BAILOUT_PENGUIN_POOL} />
                </div>
              */}

              <div className="bg-white border border-gray-200 ">
                <div className="relative w-full h-full">
                  <div
                    className={`${
                      !isConnected ? 'hidden' : ''
                    } border-b border-gray-200 py-4 px-6 justify-between w-full flex flex-row  items-center`}
                  >
                    <div className="border-green-600 font-inter font-semibold text-green-600 items-center flex flex-row">
                      <div
                        onClick={() => openAccountModal()}
                        className="flex flex-col leading-tight font-mono group w-full "
                      >
                        <div className="w-full inline-flex flex-row whitespace-nowrap leading-tight text-sm text-gray-900 font-medium">
                          {Number(
                            ethers.utils
                              .formatEther(
                                isFetched ? walletBalance.value : '0',
                              )
                              .toString(),
                          ).toFixed(3) + ' ETH'}
                        </div>
                        <div className="inline-flex leading-tight text-gray-500 text-sm font-medium ">{`${
                          wallet ? wallet.slice(0, 17) : ''
                        }...`}</div>
                      </div>
                    </div>
                    <div
                      onClick={() => openAccountModal()}
                      className="hidden sm:inline-flex h-full  items-center border-l border-gray-300 pl-3 cursor-pointer text-gray-500 hover:opacity-80 font-medium text-sm"
                    >
                      Wallet
                    </div>
                  </div>

                  <div
                    className={`${
                      isConnected ? 'hidden' : ''
                    } absolute flex flex-col justify-center h-full w-full z-[100] backdrop-blur-md  px-4 sm:px-6`}
                  >
                    <div className="justify-center  flex flex-row ">
                      <ConnectButton />
                    </div>
                  </div>
                  <div className="flex flex-col px-4 pt-3 pb-5 sm:px-6">
                    <h2
                      id="lp-widget-deposit"
                      className="text-lg font-medium text-gray-900"
                    >
                      Deposit ETH
                    </h2>
                    <div
                      className={`${
                        depositError
                          ? 'text-yellow-500 text-sm w-full flex flex-row justify-between'
                          : 'hidden'
                      }`}
                    >
                      <div className="inline-flex">{depositError}</div>
                      {depositError.indexOf('Insufficient') > -1 && (
                        <div
                          onClick={() => {
                            setDepositError('')
                            setDepositAmount(
                              Number(
                                ethers.utils.formatEther(walletBalance.value),
                              ),
                            )
                          }}
                          className="inline-flex pl-1 text-yellow-500 underline cursor-pointer"
                        >
                          set max
                        </div>
                      )}
                    </div>
                    <div
                      className={`${
                        depositError ? 'pt-0' : 'pt-2'
                      } w-full flex flex-row `}
                    >
                      <div className="relative w-full flex flex-row justify-between rounded-t-sm shadow-inner bg-gray-100">
                        <input
                          className="overflox-scroll-x w-full bg-transparent  placeholder:text-gray-300 text-xl font-mono px-3 py-2 text-gray-500 focus:ring-green-400 focus:outline-none focus:ring-0"
                          placeholder="2.50"
                          onChange={(e) => {
                            let amount = e.target.value

                            setDepositAmount(amount)
                            if (
                              amount >
                              Number(
                                ethers.utils.formatEther(walletBalance.value),
                              )
                            ) {
                              setDepositError('Insufficient balance.')
                            } else {
                              setDepositError('')
                            }
                          }}
                          value={depositAmount}
                        />
                        <div className="right-0 pr-2 py-2 text-xl font-mono text-gray-300  ">
                          <div className="bg-gray-100">ETH</div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="w-full flex flex-row justify-center px-2 py-2 border cursor-pointer group">
                        {txType === 'deposit' && (
                          <div
                            className={`${
                              isSendingTx ? 'justify-center' : 'justify-between'
                            } w-full flex flex-row items-center px-2 text-gray-400`}
                          >
                            {isSendingTx && (
                              <div className="text-gray-400">
                                Approve transaction in wallet
                              </div>
                            )}
                            {isMiningTx && (
                              <div className="text-gray-400">
                                Mining transaction...
                              </div>
                            )}

                            {isMiningTx && (
                              <svg
                                className={`animate-spin h-6 w-6 text-gray-400`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                          </div>
                        )}
                        {txType !== 'deposit' && (
                          <div className="group-hover:text-green-500">
                            Deposit
                          </div>
                        )}
                      </div>
                    </div>

                    <h2
                      id="lp-widget-deposit"
                      className="text-lg font-medium text-gray-900 pt-4"
                    >
                      Withdraw ETH
                    </h2>

                    <div
                      className={`${
                        withdrawError
                          ? 'text-yellow-500 text-sm w-full flex flex-row justify-between'
                          : 'hidden'
                      }`}
                    >
                      <div className="inline-flex">{withdrawError}</div>
                      {withdrawError.indexOf('Insufficient') > -1 && (
                        <div
                          onClick={() => {
                            setWithdrawError('')
                            setWithdrawAmount(
                              ethers.utils
                                .formatEther(balance.toString())
                                .toString(),
                            )
                          }}
                          className="inline-flex underline pl-1 text-yellow-500 cursor-pointer"
                        >
                          set max
                        </div>
                      )}{' '}
                    </div>
                    <div
                      className={`${
                        withdrawError ? 'pt-0' : 'pt-2'
                      } w-full flex flex-row `}
                    >
                      <div className="relative w-full flex flex-row justify-between rounded-t-sm shadow-inner bg-gray-100">
                        <input
                          className="w-full rounded-t-sm bg-transparent placeholder:text-gray-300 text-xl font-mono px-3 py-2 text-gray-500 focus:outline-none focus:ring-0"
                          placeholder={Number(
                            ethers.utils
                              .formatEther(balance ? balance.toString() : '0')
                              .toString(),
                          ).toFixed(2)}
                          onChange={(e) => {
                            let amount = e.target.value

                            setWithdrawAmount(amount)
                            if (
                              amount >
                              Number(
                                ethers.utils.formatEther(
                                  balance ? balance.toString() : '',
                                ),
                              )
                            ) {
                              setWithdrawError('Insufficient balance.')
                            } else {
                              setWithdrawError('')
                            }
                          }}
                          value={withdrawAmount}
                        />
                        <div className="right-0 pr-2 py-2 text-xl font-mono text-gray-300  ">
                          <div className="bg-gray-100">ETH</div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="w-full flex flex-row justify-center px-2 py-2 border cursor-pointer group">
                        {txType === 'withdraw' && (
                          <div
                            className={`${
                              isSendingTx ? 'justify-center' : 'justify-between'
                            } w-full flex flex-row items-center px-2 text-gray-400`}
                          >
                            {isSendingTx && (
                              <div className="text-gray-400">
                                Approve transaction in wallet
                              </div>
                            )}
                            {isMiningTx && (
                              <div className="text-gray-400">
                                Mining transaction...
                              </div>
                            )}

                            {isMiningTx && (
                              <svg
                                className={`animate-spin h-6 w-6 text-gray-400`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                          </div>
                        )}
                        {txType !== 'withdraw' && (
                          <div className="group-hover:text-red-500">
                            Withdraw
                          </div>
                        )}
                      </div>
                    </div>

                    <h2
                      id="lp-widget-deposit"
                      className="text-lg font-medium text-gray-900 pt-4"
                    >
                      Change Owner
                    </h2>
                    <div className={`w-full flex flex-row `}>
                      <div className="relative w-full flex flex-row justify-between rounded-t-sm shadow-inner bg-gray-100">
                        <input
                          onChange={(e) => setNewOwner(e.target.value)}
                          className="w-full rounded-t-sm bg-transparent placeholder:text-gray-300 text-xl font-mono px-3 py-2 text-gray-500 focus:outline-none focus:ring-0"
                          placeholder="0x..."
                          value={newOwner}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        transferOwner()
                      }}
                      className="w-full"
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${!isConnected ? 'hidden' : ''} mt-8`}>
                <div className="Weth-helper"></div>
              </div>
            </section>

            <div className="space-y-6 lg:col-span-2 lg:col-start-2">
              {/* Description list*/}
              <section
                aria-labelledby="branch-and-loans-overview"
                className="sm:gap-y-4 md:gap-y-8 flex flex-col"
              >
                <div>
                  <div className="w-full flex flex-row justify-center text-gray-900 rounded-t-lg border-x border-t border-gray-200 py-2 bg-white">
                    Checkout Overview
                  </div>
                  <div className="w-full grid grid-cols-5 bg-white border-gray-200 border-y border-x pb-2">
                    <div className="col-span-3 row-start-1 pt-1 pl-2 bg-white ">
                      Owner
                    </div>
                    <div className="col-span-1 row-start-1 pt-1 w-full flex flex-row justify-center">
                      Balance
                    </div>
                    <div className="col-span-1 row-start-1 pt-1 w-full flex flex-row justify-center">
                      Paused
                    </div>
                    <div className="col-span-3 row-start-2 pl-2">
                      {!isLoading ? owner : ''}
                    </div>
                    <div className="col-span-1 row-start-2 w-full flex flex-row justify-center">
                      {!isLoading
                        ? Number(
                            ethers.utils.formatEther(balance).toString(),
                          ).toFixed(2)
                        : ''}
                    </div>
                    <div className="col-span-1 row-start-2 w-full flex flex-row justify-center">
                      {!isLoading ? paused.toString() : ''}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
