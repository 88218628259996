import { ethers, utils } from 'ethers'
import { ABI, ALCHEMY_URL, CONTRACTS, MARKETPLACES } from '../config.js'
import axios from 'axios'

const provider = new ethers.providers.JsonRpcProvider(ALCHEMY_URL)

function getNFTXPriceFloor(nft) {
  return new Promise(async (resolve, reject) => {
    try {
      const sushiswap = new ethers.Contract(
        CONTRACTS.SUSHISWAP,
        ABI.SUSHISWAP,
        provider,
      )

      const nftxVault = new ethers.Contract(
        MARKETPLACES.NFTX[nft.toLowerCase()],
        ABI.NFTXVAULT,
        provider,
      )

      const [randomFee, targetFee, basePrice] = await Promise.all([
        nftxVault.randomRedeemFee(),
        nftxVault.targetRedeemFee(),
        sushiswap.getAmountsIn(utils.parseUnits('1', 'ether'), [
          CONTRACTS.WETH,
          MARKETPLACES.NFTX[nft.toLowerCase()],
        ]),
      ])

      let basePriceEth = Number(utils.formatEther(basePrice[0]).toString())

      let randomAdjusted = utils.parseUnits('1', 'ether').add(randomFee)
      let targetAdjusted = utils.parseUnits('1', 'ether').add(targetFee)
      randomAdjusted = Number(utils.formatEther(randomAdjusted).toString())
      targetAdjusted = Number(utils.formatEther(targetAdjusted).toString())

      let randomPrice = basePriceEth * randomAdjusted
      let targetPrice = basePriceEth * targetAdjusted

      let priceObject = {
        address: nftxVault.address,
        collectionUrl: `https://nftx.io/vault/${nftxVault.address}/buy/`,
        priceCurrency: 'ETH',
        retrievedAt: new Date(Date.now()).toISOString(),
      }

      resolve({
        random: { floorPrice: randomPrice, ...priceObject },
        target: { floorPrice: targetPrice, ...priceObject },
        all: {
          randomePrice: randomPrice,
          targetPrice: targetPrice,
          ...priceObject,
        },
      })
    } catch (error) {
      console.log('nftxPriceFloor error', error)
      resolve()
    }
  })
}

const getSudoPoolExpanded = (_nft, pools, refresh = false) => {
  const parsePoolData = (poolData) => {
    return {
      address: poolData.pool,
      token: poolData.token,
      nftsAmount: poolData.nftsAmount
        ? Number(poolData.nftsAmount.toString())
        : '',
      buyCost: poolData.buyCost ? poolData.buyCost.toString() : '',
    }
  }

  return new Promise(async (resolve, reject) => {
    try {
      const nft = _nft.toLowerCase()
      const storageKey = `${nft}:${'sudoswap_prices'}`
      const maxBlockLatency = 2

      const _latestBlock = await provider.getBlock()
      const latestBlock = _latestBlock.number

      const cachedPrices = localStorage.getItem(storageKey)
      if (cachedPrices !== null) {
        const { prices, lastSync } = JSON.parse(cachedPrices)
        if (latestBlock - lastSync <= maxBlockLatency) {
          console.log('Got sudoswap prices from cache')

          resolve(prices)
        }
      }

      const Sudohelper_expanded = new ethers.Contract(
        CONTRACTS.SUDOPOOLHELPER,
        ABI.SUDOPOOLHELPER,
        provider,
      )

      let chunkedPools = []
      const chunkSize = 10
      for (let i = 0; i < pools.length; i += chunkSize) {
        const chunk = pools.slice(i, i + chunkSize)
        chunkedPools.push(chunk)
      }

      let chunkedCalls = []
      const chunkSizeCalls = 50
      for (let i = 0; i < chunkedPools.length; i += chunkSizeCalls) {
        const chunk = chunkedPools.slice(i, i + chunkSizeCalls)
        chunkedCalls.push(chunk)
      }

      let prices = []
      for (let i = 0; i < chunkedCalls.length; i++) {
        const allPromises = await Promise.all(
          chunkedCalls[i].map((addresses) =>
            Sudohelper_expanded.getPools(addresses).catch((e) => {
              console.log(e)
              return undefined
            }),
          ),
        )

        prices = prices.concat(
          allPromises.flat().map((pool) => {
            if (pool) {
              return parsePoolData(pool)
            }
          }),
        )
      }
      prices = prices.filter(
        (pool) =>
          pool.nftsAmount > 0 &&
          pool.token === '0x0000000000000000000000000000000000000000',
      )

      localStorage.setItem(
        storageKey,
        JSON.stringify({ prices, lastSync: latestBlock }),
      )
      resolve(prices)
    } catch (error) {
      reject(error)
    }
  })
}

const getCollectionSudoPools = (_nft, includePrices = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const nft = _nft.toLowerCase()
      const storageKey = `${nft}:${'sudoswap_pools'}`
      const maxBlockLatency = 2

      // Check if a fresh pool list is available in the cache
      const cachedPools = localStorage.getItem(storageKey)
      if (cachedPools !== null) {
        const { pools, lastSync } = JSON.parse(cachedPools)

        const _latestBlock = await provider.getBlock()
        const latestBlock = _latestBlock.number

        if (latestBlock - lastSync <= maxBlockLatency) {
          console.log('Got sudoswap pool list from cache')
          resolve(pools)
        }
      }

      // If not, fetch the pool list from the API
      const response = await axios.get(
        `https://us-central1-bailout-cee17.cloudfunctions.net/getSudoPoolsForNft?nft=${nft}&includePrices=${includePrices}`,
      )

      const pools = response.data
      /*
      const { isIndexed, pools, lastSync } = response.data
      if (!isIndexed)
        reject(new Error('Requested nft collection is not indexed'))

      localStorage.setItem(storageKey, JSON.stringify({ pools, lastSync }))
      */
      resolve(pools)
    } catch (error) {
      reject(error)
    }
  })
}

const getCollectionSudoPrices = (_nft) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pools = await getCollectionSudoPools(_nft, false)
      const prices = await getSudoPoolExpanded(_nft, pools, false)
      resolve(prices)
    } catch (error) {
      reject(error)
    }
  })
}

const getSudoPriceFloor = (_nft) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pools = await getCollectionSudoPools(_nft, false)
      const prices = await getSudoPoolExpanded(_nft, pools, false)
      let lowestPool = prices.sort((a, b) =>
        Number(ethers.BigNumber.from(a.buyCost).sub(b.buyCost)),
      )[0]
      let priceObject = {
        floorPrice: Number(utils.formatEther(lowestPool.buyCost).toString()),
        priceCurrency: 'ETH',
        retrievedAt: new Date(Date.now()).toISOString(),
        collectionUrl: `https://sudoswap.xyz/#/browse/buy/${_nft}`,
      }
      resolve(priceObject)
    } catch (error) {
      reject(error)
    }
  })
}

const getNftFloorPrices = (_nft) => {
  return new Promise(async (resolve, reject) => {
    try {
      const [
        nftPriceFloor,
        nftxPriceFloor,
        sudoPriceFloor,
      ] = await Promise.all([
        axios.get(
          `https://eth-mainnet.g.alchemy.com/nft/v2/${'BQoJ4oYh8QDYABD3ISP8g2r8MIO5Ubdz'}/getFloorPrice?contractAddress=${_nft}`,
        ),
        getNFTXPriceFloor(_nft),
        getSudoPriceFloor(_nft),
      ])
      let floorPrices = {
        ...nftPriceFloor.data,
        nftx_random: nftxPriceFloor.random,
        nftx_target: nftxPriceFloor.target,
        sudoswap: sudoPriceFloor,
      }
      resolve(floorPrices)
    } catch (error) {
      reject(error)
    }
  })
}

export {
  getCollectionSudoPrices,
  getNFTXPriceFloor,
  getSudoPriceFloor,
  getNftFloorPrices,
}
