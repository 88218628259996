import React from 'react'
import ReactDOM from 'react-dom/client'
import Admin from './pages/Admin'
import AdminCheckout from './pages/AdminCheckout'
import App_V2 from './pages/App_V2'
import Branch_V2 from './pages/Branch_V2'
import Collection_V2 from './pages/Collection_V2'
import Wallet from './pages/Wallet'
import {
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
} from '@rainbow-me/rainbowkit'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import './index.css'

const firebaseConfig = {
  apiKey: 'AIzaSyCOehHrDiHlXZrke9Fh2DwdiSmAjIxswaw',
  authDomain: 'bailout-cee17.firebaseapp.com',
  projectId: 'bailout-cee17',
  storageBucket: 'bailout-cee17.appspot.com',
  messagingSenderId: '916786923099',
  appId: '1:916786923099:web:dad10d55a26c0f2d3a217e',
  measurementId: 'G-2GRMNDPKQJ',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ apiKey: '-icGAQDy6THpkE5XniTZih8bKwCgVmFK' }),
    publicProvider(),
  ],
)

const { connectors } = getDefaultWallets({
  appName: 'Bailout Checkout',
  chains,
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider
      chains={chains}
      theme={lightTheme({
        ...lightTheme.accentColors.green,
      })}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App_V2 analytics={analytics} />} />
          <Route path="admin" element={<Admin analytics={analytics} />} />
          <Route path="admincheckout" element={<AdminCheckout />} />
          <Route path="wallet" element={<Wallet analytics={analytics} />}>
            <Route path=":address" element={<Wallet analytics={analytics} />} />
            <Route index element={<Wallet analytics={analytics} />} />
          </Route>
          <Route path="branch" element={<Branch_V2 analytics={analytics} />}>
            <Route
              path=":address"
              element={<Branch_V2 analytics={analytics} />}
            />
            <Route index element={<Branch_V2 analytics={analytics} />} />
          </Route>
          <Route
            path="collection"
            element={<Collection_V2 analytics={analytics} />}
          >
            <Route
              path=":address"
              element={<Collection_V2 analytics={analytics} />}
            />
            <Route index element={<Collection_V2 analytics={analytics} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RainbowKitProvider>
  </WagmiConfig>,
)
