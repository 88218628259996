import { useState, useEffect } from 'react'
import TUBBYCATS from '../assets/nfts/tubbycats.png'
import MILADIES from '../assets/nfts/milady.jpg'
import OTHERDEEDS from '../assets/nfts/otherdeeds.jpg'

import { getBranchData } from '../helpers/bailout'

const branches = [
  {
    name: 'Otherdeeds for Otherside',
    maxLoan: '1.25 ETH',
    liquidation: '7 Days to Liquidation',
    deposits: '3.00 ETH',
    borrows: '1.25 ETH',
    activeLoans: '1',
    address: '100k NFT Collection',
    image: OTHERDEEDS,
  },
  {
    name: 'Milady Maker',
    maxLoan: '0.30 ETH',
    liquidation: '7 Days to Liquidation',
    deposits: '1.00 ETH',
    borrows: '0.30 ETH',
    activeLoans: '1',
    address: '10.0k NFT Collection',
    image: MILADIES,
  },
  {
    name: 'Tubbycats',
    maxLoan: '0.08 ETH',
    deposits: '2.00 ETH',
    borrows: '0.72 ETH',
    activeLoans: '9',
    liquidation: '7 Days to Liquidation',
    address: '20.0k NFT Collection',
    image: TUBBYCATS,
  },
]

const loans = [
  {
    name: 'Otherdeeds',
    branch: '[1.25 ETH]',
    id: '54725',
    duration: '3 Days 12 Hours',
    liquidation: '7 Days to Liquidation',
    debt: '1.28 ETH',
    priceFloor: '1.80 ETH',
    profit: '0.52 ETH',
    isLoss: false,
    address: '100k NFT Collection',
    image:
      'https://res.cloudinary.com/alchemyapi/image/upload/w_256,h_256/mainnet/eede5725e068350e9d4178e109fba884.jpg',
  },
  {
    name: 'Milady',
    branch: '[0.30 ETH]',
    id: '264',
    duration: '5 Days 2 Hours',
    liquidation: '7 Days to Liquidation',
    debt: '0.31 ETH',
    priceFloor: '0.42 ETH',
    profit: '0.11 ETH',
    isLoss: false,
    address: '10.0k NFT Collection',
    image: 'https://www.miladymaker.net/milady/264.png',
  },
  {
    name: 'Tubbycats',
    branch: '[0.08 ETH]',
    id: '1206',
    duration: '6 Days 20 Hours',
    debt: '0.08 ETH',
    priceFloor: '0.11 ETH',
    profit: '0.03 ETH',
    isLoss: false,
    liquidation: '7 Days to Liquidation',
    address: '20.0k NFT Collection',
    image:
      'https://ipfs.io/ipfs/QmXAYm2pZ7onGmpCjkCqKFLD4d1qaTdJcKqBMTrD63Ew11/5335.png',
  },
  {
    name: 'Tubbycats',
    branch: '[0.08 ETH]',
    id: '1261',
    duration: '0 Days 0 Hours',
    debt: '0.12 ETH',
    priceFloor: '0.11 ETH',
    profit: '0.01 ETH',
    isLoss: true,
    liquidation: '7 Days to Liquidation',
    address: '20.0k NFT Collection',
    image:
      'https://res.cloudinary.com/alchemyapi/image/upload/w_256,h_256/mainnet/c37411eda748a8e5f683f4fa8266a313.png',
  },
]

export default function Admin() {
  useEffect(() => {
    getBranchData('0x82c887e263985CcF01705022E46C1B3ad1e5a8Ff')
      .then((result) => console.log(result))
      .catch((error) => console.log(error))
  }, [])
  return (
    <div className="w-full h-full flex flex-col items-center  bg-gray-50">
      <div className="relative h-full flex flex-col  gap-y-6 bg-white border-x border-gray-300">
        <div className="w-full h-8 bg-gradient-to-r from-green-600 via-green-500 to-green-400 " />

        <div className="max-w-5xl w-full px-4 sm:px-6 lg:px-8 pb-4 bg-white">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 flex flex-row ">
                🏦 Branches
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all active Bailout branches, including total assets
                and liabilites.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center font-semibold rounded-md  bg-gradient-to-r from-green-600 via-green-500 to-green-400 px-4 py-2 text-sm text-white shadow-sm hover:from-green-400 hover:to-green-600 hover:bg-gradient-to-tr focus:outline-none focus:ring-0 sm:w-auto transition-all duration-1000"
              >
                Gnosis Safe
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Max Loan
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Deposits
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Borrowed
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Loans
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {branches.map((branch) => (
                        <tr key={branch.name}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={branch.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4 max-w-[230px]">
                                <div className="font-medium text-gray-900">
                                  {branch.name}
                                </div>
                                <div className="text-ellipsis overflow-hidden text-gray-500">
                                  {branch.address}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900 font-mono">
                              {branch.maxLoan}
                            </div>
                            <div className="text-gray-500">
                              {branch.liquidation}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 font-mono text-sm text-green-500 ">
                            {branch.deposits}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 font-mono text-sm text-yellow-500">
                            {branch.borrows}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {branch.activeLoans}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              Active
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="#"
                              className="text-green-600 hover:text-green-900"
                            >
                              Edit
                              <span className="sr-only">, {branch.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-1 border-b border-gray-300 " />
        {/* NFT LOANS */}
        <div className="max-w-5xl w-full px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900 flex flex-row ">
                🧾 Active Loans
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all active Bailout loans, including collateral nft and
                outstanding debt.
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center font-semibold rounded-md  bg-gradient-to-r from-green-600 via-green-500 to-green-400 px-4 py-2 text-sm text-white shadow-sm hover:from-green-400 hover:to-green-600 hover:bg-gradient-to-tr focus:outline-none focus:ring-0 sm:w-auto transition-all duration-1000"
              >
                Gnosis Safe
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Liquidation
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Debt
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Price Floor
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Profit
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white overflow-y-scroll">
                      {loans.map((loan) => (
                        <tr key={loan.name + ':' + loan.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={loan.image}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4 max-w-[230px]">
                                <div className="font-medium text-gray-900">
                                  #{loan.id}
                                </div>
                                <div className="text-ellipsis text-xs font-mono overflow-hidden text-gray-500">
                                  {loan.branch} {loan.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                            {loan.duration}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-500 font-semibold">
                            {loan.debt}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 font-mono text-sm text-gray-400">
                            {loan.priceFloor}
                          </td>
                          <td
                            className={`${
                              loan.isLoss ? 'text-red-500' : 'text-green-500'
                            } whitespace-nowrap px-3 py-4 font-mono text-sm`}
                          >
                            {loan.profit}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="#"
                              className={`${
                                loan.duration === '0 Days 0 Hours'
                                  ? 'text-red-500 hover:text-red-900 cursor-pointer'
                                  : 'text-gray-300 cursor-default'
                              }  `}
                            >
                              Liquidate
                              <span className="sr-only">, {loan.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-8 absolute bottom-0  bg-gradient-to-r from-green-600 via-green-500 to-green-600 " />
      </div>
      {/* ////////////// */}
    </div>
  )
}
