import { useEffect, useState } from 'react'
import { setUserProperties, logEvent } from 'firebase/analytics'
import { useNavigate } from 'react-router-dom'
import { useConnectModal, useAccountModal } from '@rainbow-me/rainbowkit'
import { useAccount, useBalance, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import axios from 'axios'
import BAILOUT_LOGO from '../assets/videos/BAILOUT_LOGO.gif'
import BAILOUT_LOGO_PLACEHOLDER from '../assets/images/BAILOUT_LOGO_PLACEHOLDER.webp'
import { ABI, CONTRACTS } from '../config'
import '@rainbow-me/rainbowkit/styles.css'

import PudgyPenguinsBanner from '../assets/images/PudgyPenguins_Banner.webp'
import TubbyCatsBanner from '../assets/images/TubbyCats_Banner.webp'
import PudgyPenguinsIcon from '../assets/images/PudgyPenguins_Icon.png'
import TubbyCatsIcon from '../assets/images/TubbyCats_Icon.png'

const ALCHEMY_URL =
  'https://eth-mainnet.g.alchemy.com/v2/-icGAQDy6THpkE5XniTZih8bKwCgVmFK'
const provider = new ethers.providers.JsonRpcProvider(ALCHEMY_URL)

const tubbycats = '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd'
const miladys = '0x5af0d9827e0c53e4799bb226655a1de152a425a5'
const otherdeeds = '0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258'
const pudgypenguins = '0xbd3531da5cf5857e7cfaa92426877b022e612cf8'

const BailoutPools = {
  [tubbycats]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.08',
    name: 'Tubbycats',
  },
  [miladys]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '0.30',
    name: 'Milady',
  },
  [otherdeeds]: {
    address: '0x82c887e263985ccf01705022e46c1b3ad1e5a8ff',
    maxLoan: '1.50',
    name: 'Otherdeeds',
  },
  [pudgypenguins]: {
    address: '0x73D843995eEF3A2A8296d756cef488eaf81Bde62',
    maxLoan: '2.50',
    name: 'Pudgy Penguins',
  },
}

const defaultCollectionMetadata = [
  {
    primaryContract: '0xbd3531da5cf5857e7cfaa92426877b022e612cf8',
    name: 'Pudgy Penguins',
    floorAsk: { price: { amount: { decimal: 3.5 } } },
  },
  {
    primaryContract: '0xca7ca7bcc765f77339be2d648ba53ce9c8a262bd',
    name: 'tubby cats',
    floorAsk: { price: { amount: { decimal: 0.082 } } },
  },
]

export default function App_V2({ analytics }) {
  let navigate = useNavigate()

  const [isLogoLoaded, setIsLogoLoaded] = useState(false)

  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * //
  // * > WEB3 INTEGRATIONS
  const { openConnectModal } = useConnectModal()
  const { openAccountModal } = useAccountModal()
  const { address, isConnecting, isConnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if (isReconnected) {
        setUserProperties(analytics, { wallet: 'wallet:' + address })
      } else {
        logEvent(analytics, 'login', {
          method: connector.name,
          wallet: 'wallet:' + address,
        })
        setUserProperties(analytics, { wallet: 'wallet:' + address })
      }
    },
  })
  const { data: walletBalance, isFetched: isWalletBalanceFetched } = useBalance(
    {
      addressOrName: address,
    },
  )

  const [isLoading, setIsLoading] = useState(true)
  const [collectionMetadata, setCollectionMetadata] = useState(
    defaultCollectionMetadata,
  )

  console.log('collectionMetadata', collectionMetadata)

  const [isLoadingLiquidity, setIsLoadingLiquidity] = useState(true)
  const [poolLiquidity, setPoolLiquidity] = useState({
    [pudgypenguins]: '0',
    [tubbycats]: '0',
  })

  useEffect(() => {
    const featuredCollections_cacheKey = `featuredCollections`
    let featuredCollectionsCache = localStorage.getItem(
      featuredCollections_cacheKey,
    )
    if (featuredCollectionsCache) {
      featuredCollectionsCache = JSON.parse(featuredCollectionsCache)
      setCollectionMetadata(featuredCollectionsCache)
      setIsLoading(false)
    }

    Promise.all([
      axios.get(
        `https://us-central1-bailout-cee17.cloudfunctions.net/getCollectionMetadata?nft=${pudgypenguins}&includeSalesData=true`,
      ),
      axios.get(
        `https://us-central1-bailout-cee17.cloudfunctions.net/getCollectionMetadata?nft=${tubbycats}&includeSalesData=true`,
      ),
    ]).then((responses) => {
      const featuredCollectionsMetadata = responses.map(
        (response) => response.data,
      )
      setCollectionMetadata(featuredCollectionsMetadata)
      setIsLoading(false)
      localStorage.setItem(
        featuredCollections_cacheKey,
        JSON.stringify(featuredCollectionsMetadata),
      )
    })
    /* * * */
    const liquidity_cacheKey = `featuredCollectionsLiquidity`
    let liquidityCache = localStorage.getItem(liquidity_cacheKey)
    if (liquidityCache) {
      liquidityCache = JSON.parse(liquidityCache)
      setPoolLiquidity(liquidityCache)
      setIsLoadingLiquidity(false)
    }

    const WETH = new ethers.Contract(CONTRACTS.WETH, ABI.WETH, provider)
    Promise.all([
      WETH.balanceOf(BailoutPools[pudgypenguins].address),
      WETH.balanceOf(BailoutPools[tubbycats].address),
    ]).then((result) => {
      console.log('result', result)
      let collectionsLiquidity = {
        [pudgypenguins]: result[0].toString(),
        [tubbycats]: result[1].toString(),
      }

      setPoolLiquidity(collectionsLiquidity)
      setIsLoadingLiquidity(false)
      localStorage.setItem(
        liquidity_cacheKey,
        JSON.stringify(collectionsLiquidity),
      )
    })
  }, [])

  console.log('collectionMetadata', collectionMetadata)

  const WalletButton = () => {
    if (!isConnected) {
      return (
        <div
          onClick={() => {
            if (!isConnecting) {
              openConnectModal()
            }
          }}
          className="leading-tight sm:leading-normal font-proximaNova font-semibold text-white/80 border- border-white/50 opacity-80 rounded-sm px-1 sm:px-2 sm:text-lg hover:opacity-100 hover:border-white/80 transition-all cursor-pointer"
        >
          {!isConnecting ? 'Connect' : 'Connecting'}
        </div>
      )
    } else {
      return (
        <div className="gap-x-2 mt-[6px]- sm:mt-0 flex flex-row sm:items-center">
          <div className="sm:border-l border-green-600 px-2 pl-3  gap-x-2 font-inter font-semibold text-green-600 items-center flex flex-row">
            <div
              onClick={() => openAccountModal()}
              className="hidden sm:flex flex-col leading-tight font-mono group cursor-pointer"
            >
              <div className="inline-flex whitespace-nowrap leading-tight text-sm text-green-400/90">
                {Number(
                  ethers.utils
                    .formatEther(
                      isWalletBalanceFetched ? walletBalance.value : '0',
                    )
                    .toString(),
                ).toFixed(3) + ' ETH'}
              </div>
              <div className="inline-flex leading-tight text-sm group-hover:opacity-80">{`${address.slice(
                0,
                6,
              )}...`}</div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate('/wallet/' + address)
            }}
            className="leading-tight sm:leading-normal inline-flex px-1 sm:px-0 border-text-green-500  opacity-80 sm:opacity-100 hover:opacity-100 sm:pt-0 h-full items-center sm:border-l border-green-600 sm:pl-3 cursor-pointer text-white sm:text-green-500 font-proximaNova font-semibold hover:to-green-500"
          >
            Payments
          </div>
        </div>
      )
    }
  }

  return (
    <div className="w-full h-screen relative overflow-x-hidden bg-gray-50">
      <div className="relative  bg-black ">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 py-5 pb-5 sm:pt-6 sm:pb-12 xl:pt-8 xl:pb-16 ">
          <nav
            className="relative flex flex-row items-center justify-between sm:h-10  sm:overflow-hidden"
            aria-label="Global"
          >
            <div className="flex items-center flex-1 sm:mx-0  md:inset-y-0 left-0">
              <div className="flex items-start justify-start  w-full sm:w-auto">
                <img
                  src={BAILOUT_LOGO_PLACEHOLDER}
                  className={`${
                    isLogoLoaded ? 'hidden' : ''
                  } w-l w-40 h-[22.89px] sm:h-[34.34px] lg:h-[40px] sm:w-60 lg:w-[280px]`}
                />
                <img
                  src={BAILOUT_LOGO}
                  className={`${
                    isLogoLoaded ? '' : 'hidden'
                  } w-l w-40 h-[22.89px] sm:h-[34.34px] lg:h-[40px] sm:w-60 lg:w-[280px]`}
                  onLoad={() => setIsLogoLoaded(true)}
                />
              </div>
            </div>
            <div className="absolute flex items-center justify-end inset-y-0 right-0">
              <span className="h-full inline-flex">
                <WalletButton />
              </span>
            </div>
          </nav>
        </div>

        <main className="border-b border-gray-300 sm:border-b-none sm:card-overlay-shadow-none mx-auto max-w-4xl py-4 sm:pt-6 xl:py-0 xl:pt-8  bg-white rounded-t-3xl ">
          <div className="text-center px-3 sm:px-4">
            <h1 className="font-inter text-3xl space-x-2 sm:space-x-4 tracking-tight sm:pb-5  items-center flex justify-center font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
              <div className="inline-flex justify-center items-center rounded-xl">
                <span className="leading-none block xl:inline font-proximaNova font-bold text-gray-900">
                  Buy now pay later for
                </span>
              </div>
              <div className="inline-flex justify-center items-center">
                <span className="leading-none tracking-normal font-proximaNova font-bold block text-green-500 bg-clip-text text-transparent bg-gradient-to-r from-green-600 via-green-500 to-green-500 xl:inline">
                  NFTs
                </span>
              </div>
            </h1>
            <p className="hidden sm:block mt-3 font-proximaNova max-w-md mx-auto text-base text-gray-900 sm:text-lg md:mt-1 md:text-xl md:max-w-3xl">
              Pay as little as 15% upfront. No lock-up period, sell your nft at
              any time after the first installment has been paid. No price floor
              liquidation risk, all terms are fixed.
            </p>
            <p className="block sm:hidden mt-3 sm:mt-3 font-proximaNova max-w-md mx-auto sm:font-semibold text-gray-900 sm:text-lg md:mt-1 md:text-xl md:max-w-3xl">
              Pay as little as 15% upfront
            </p>
            <p className="block sm:hidden sm:mt-1 font-proximaNova max-w-md mx-auto sm:font-semibold text-gray-900 sm:text-lg md:mt-1 md:text-xl md:max-w-3xl">
              Pay the remainder up to 7 days later
            </p>
            <p className="block sm:hidden sm:mt-1 font-proximaNova max-w-md mx-auto sm:font-semibold text-gray-900 sm:text-lg md:mt-1 md:text-xl md:max-w-3xl">
              No liquidation risk, durations are always fixed
            </p>
          </div>
          <div className="hidden xl:block h-8 sm:h-6 xl:h-10 card-overlay-shadow" />
        </main>
      </div>
      <div className="w-full flex flex-col justify-start  ">
        <div
          className={` md:pt-4 xl:pt-8 w-full flex flex-row items-start justify-center xl:max-w-7xl xl:mx-auto`}
        >
          <div className="flex flex-col xl:flex-row items-center xl:items-start justify-center xl:justify-start min-w-full max-w-full  ">
            <div className="hidden xl:block xl:max-w-[418px] w-full bg-white grow-0 mr-16">
              <div className="w-full h-6 xl:h-10 sm:rounded-t-md bg-gradient-to-r from-green-600 via-green-500 to-green-400 " />

              <h3 className="text-xl font-proximaNova font-bold tracking-tight text-gray-900 sm:text-3xl px-4 pt-1 xl:pt-3 sm:border-x border-gray-300">
                How it works
              </h3>

              <div className="hidden font-proximaNova xl:block pb-1 sm:pb-4 leading-tight sm:leading-normal text-lg text-gray-500 px-4 sm:pt-1 sm:border-x border-gray-300">
                Bailout Pay works by taking a users initial downpayment and then
                collateralizing the purchased nft in a Bailout lending pool.
              </div>

              <dl className="pt-1 lg:pt-3 xl:pt-5 pb-2 sm:pb-4 space-y-2 sm:space-y-4 xl:border-t px-4 sm:border-x border-gray-300">
                <div key="1" className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg font-proximaNova font-medium leading-6 text-gray-900">
                      1. Make an initial downpayment
                    </p>
                  </dt>
                  <dd className="leading-tight sm:leading-normal sm:mt-1 ml-16 font-proximaNova text-gray-500">
                    Pay as little as 20% of the price of the nft upfront. You
                    can sell your nft at any time after this payment.
                  </dd>
                </div>
                <div key="2" className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-tr from-green-600 via-green-500 to-green-500 text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg font-proximaNova font-medium leading-6 text-gray-900">
                      2. Pay second installment in 7 days
                    </p>
                  </dt>
                  <dd className="leading-tight sm:leading-normal sm:mt-1 ml-16 font-proximaNova text-gray-500">
                    Pay the remainder within 7 days along with a capped max fee
                    of about $2-$5 for Tubby Cats and $50-$60 for Pudgy
                    Penguins. No liquidation risk within this timeframe.
                  </dd>
                </div>
              </dl>
              <div className="w-full h-6 xl:h-10 sm:rounded-b-md bg-gradient-to-r from-green-600 via-green-500 to-green-400 " />
            </div>

            <div className="px-4 sm:px-4 sm:pt-4 max-w-4xl block grow w-full bg-gray-50 ">
              <div className="hidden sm:block ">
                <h2 className="mt-2 sm:mt-0 text-xl font-proximaNova font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Featured Collections
                </h2>
                <p className="mt-1 text-md sm:text-xl font-proximaNova font-regular text-gray-500 sm:mt-1">
                  These are the nft collections currently available for purchase
                  with Bailout buy-now-pay-later checkout service. More
                  collections coming soon.
                </p>
              </div>
              <div
                className={`mt-1 sm:mt-6 sm:border-t border-gray-300  pt-4 sm:pt-8 grid grid-cols-1 gap-y-6 sm:gap-y-0 sm:grid-cols-2 sm:gap-x-8 transition-all duration-[1000ms]`}
              >
                {/* NFT Collections */}
                {collectionMetadata.map((collection) => {
                  return (
                    <div
                      key={collection.primaryContract}
                      onClick={() => {
                        logEvent(analytics, 'select_content', {
                          content_type: 'collection_page',
                          item_id: collection.name,
                        })
                        navigate(`/collection/${collection.primaryContract}`)
                      }}
                      className=" col-span-1 w-full h-full flex flex-col border- border-gray-200 shadow group cursor-pointer hover:animate-pulse- hover:shadow-md transition-all duration-200"
                    >
                      <img
                        rel="preload"
                        src={
                          collection.primaryContract.toLowerCase() ===
                          pudgypenguins
                            ? PudgyPenguinsBanner
                            : TubbyCatsBanner
                        }
                        className="w-full h-full"
                      />
                      <div className="w-full bg-white border-t border-gray-200">
                        <div
                          className={` flex flex-row items-center bg-white transition-all duration-200 w-full `}
                        >
                          <img
                            rel="preload"
                            className={`h-16 w-16`}
                            src={
                              collection.primaryContract.toLowerCase() ===
                              pudgypenguins
                                ? PudgyPenguinsIcon
                                : TubbyCatsIcon
                            }
                          />
                          <div className="flex flex-col w-full px-4">
                            <h3 className="text-lg font-medium text-gray-900">
                              {collection?.name}
                            </h3>
                            <h5 className="w-full text-sm flex flex-row justify-between items-center text-gray-800 tracking-tight font-inter">
                              <div className="inline-flex font-proximaNova flex-row items-center">
                                <div>
                                  Floor:{' '}
                                  <div className="inline-flex font-semibold">
                                    {Number(
                                      collection.floorAsk?.price?.amount
                                        ?.decimal,
                                    ).toFixed(3)}
                                  </div>
                                </div>
                                <div className="inline-flex pb-[2px] pl-[1px]">
                                  <svg
                                    width="9"
                                    height="12"
                                    viewBox="0 0 6 9"
                                    fill="inherit"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
                                      fill="#777E91"
                                    />
                                    <path
                                      d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
                                      fill="#777E91"
                                    />
                                  </svg>
                                </div>
                              </div>

                              {/*
                              <div>
                                Supply:{' '}
                                <div className="inline-flex font-semibold">
                                  {Number(collection?.tokenCount)}
                                </div>
                              </div>
                                    */}

                              <div className="inline-flex font-proximaNova flex-row items-center">
                                <div>
                                  Downpayment:{' '}
                                  <div className="inline-flex font-semibold">
                                    {Number(
                                      Number(
                                        collection.floorAsk?.price?.amount
                                          ?.decimal,
                                      ) -
                                        BailoutPools[
                                          collection.primaryContract.toLowerCase()
                                        ].maxLoan,
                                    ).toFixed(3)}
                                  </div>
                                </div>
                                <div className="inline-flex pb-[2px] pl-[1px]">
                                  <svg
                                    width="9"
                                    height="12"
                                    viewBox="0 0 6 9"
                                    fill="inherit"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.56641 4.55935L2.76099 0L0 4.56239L2.78244 6.22185L5.56641 4.55935Z"
                                      fill="#777E91"
                                    />
                                    <path
                                      d="M5.56641 5.11627L2.77631 6.74082L0 5.11627L2.78244 8.99999L5.56641 5.11627Z"
                                      fill="#777E91"
                                    />
                                  </svg>
                                </div>
                              </div>

                              {/* * * */}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* Liquidity */}
                      <div
                        className={`${
                          isLoadingLiquidity ? ' opacity-0 ' : 'opacity-100 '
                        }  text-gray-400  whitespace-nowrap transition-all duration-300 w-full flex flex-row justify-center items-center tracking-snug leading-tight text-center py-1 bg-white border-t border-gray-200`}
                      >
                        {Math.floor(
                          Number(
                            ethers.BigNumber.from(
                              poolLiquidity[collection?.primaryContract],
                            )
                              .div(
                                ethers.utils.parseEther(
                                  BailoutPools[
                                    collection.primaryContract
                                  ].maxLoan.toString(),
                                ),
                              )
                              .toString(),
                          ),
                        ) === 0 && 'Pool liquidity is fully utilized'}
                        <div className="text-green-400">
                          {Math.floor(
                            Number(
                              ethers.BigNumber.from(
                                poolLiquidity[collection?.primaryContract],
                              )
                                .div(
                                  ethers.utils.parseEther(
                                    BailoutPools[
                                      collection.primaryContract
                                    ].maxLoan.toString(),
                                  ),
                                )
                                .toString(),
                            ),
                          ) === 1 && 'Pool has liquidity for 1 purchase'}
                        </div>
                        <div className="text-green-400">
                          {Math.floor(
                            Number(
                              ethers.BigNumber.from(
                                poolLiquidity[collection?.primaryContract],
                              )
                                .div(
                                  ethers.utils.parseEther(
                                    BailoutPools[
                                      collection.primaryContract
                                    ].maxLoan.toString(),
                                  ),
                                )
                                .toString(),
                            ),
                          ) > 1 &&
                            `Pool has liquidity for ${Math.floor(
                              Number(
                                ethers.BigNumber.from(
                                  poolLiquidity[collection?.primaryContract],
                                )
                                  .div(
                                    ethers.utils.parseEther(
                                      BailoutPools[
                                        collection.primaryContract
                                      ].maxLoan.toString(),
                                    ),
                                  )
                                  .toString(),
                              ),
                            )} purchases`}
                        </div>
                      </div>
                      {/* * */}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
